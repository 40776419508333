import React from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from "react-oidc-context";
import { Button } from "../components/ui/button";
import { Card, CardContent } from "../components/ui/card";
import { 
  Users, 
  LineChart, 
  Lock, 
  Target, 
  ArrowUpCircle, 
  Activity, 
  Check 
} from 'lucide-react';

const UnauthLanding = () => {
  const auth = useAuth();

  return (
    <div className="min-h-screen bg-[#f4f2ed]">
      {/* Navigation */}
      <nav className="bg-white shadow-sm">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between h-16">
            <div className="flex items-center">
              <img 
                src="/logo.png"
                alt="Logo"
                className="h-8 w-auto"
              />
            </div>
            <div className="flex items-center">
              <Button 
                onClick={() => auth.signinRedirect()}
                className="bg-[#1f513f] hover:bg-[#f4f2ed] hover:text-[#1f513f] text-white border border-[#1f513f]"
              >
                Sign In
              </Button>
            </div>
          </div>
        </div>
      </nav>


      <div className="relative w-full bg-[#f4f2ed] overflow-hidden">
  {/* Background SVG container - make it span full width */}
  <div 
    className="absolute inset-0 w-full h-full"
    style={{
      backgroundImage: 'url(/contour-lines.svg)',
      backgroundRepeat: 'repeat',
      backgroundSize: '100% auto',
      backgroundPosition: 'center',
    }}
  />
  
  {/* Content container */}
  <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-16 pb-24 relative z-10">
    <div className="lg:grid lg:grid-cols-12 lg:gap-8">
      {/* Rest of your content stays the same */}
      <div className="sm:text-center md:max-w-2xl md:mx-auto lg:col-span-6 lg:text-left">
        <h1 className="text-4xl font-bold text-[#20201d] sm:text-5xl md:text-6xl">
          Transform Customer Success Through Consumption
        </h1>
        <p className="mt-6 text-xl text-[rgba(66,59,47,0.75)]">
          Move beyond transaction tracking to drive meaningful consumption growth. Our platform helps teams align with customer workflows and evolve through every stage of maturity.
        </p>
        <div className="mt-10">
          <Button
            onClick={() => auth.signinRedirect()}
            className="w-full sm:w-auto bg-[#1f513f] hover:bg-[#f4f2ed] hover:text-[#1f513f] text-white border border-[#1f513f] px-8 py-4 text-lg"
          >
            Get Started
          </Button>
        </div>
      </div>
      <div className="mt-12 lg:mt-0 lg:col-span-6">
        <div className="bg-white rounded-lg shadow-xl p-3">
          <img
            src="/dashboard-preview.svg"
            alt="Dashboard preview"
            className="rounded-lg shadow-xl"
          />
        </div>
      </div>
    </div>
  </div>
</div>

{/* Features Section */}
<div className="bg-white py-24">
  <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
    <div className="text-center">
      <h2 className="text-3xl font-bold text-[#20201d]">
        Built for Modern Customer Growth
      </h2>
    </div>
    <div className="mt-12 grid gap-8 sm:grid-cols-2 lg:grid-cols-3">
      <Card>
        <CardContent className="pt-6">
          <h3 className="text-xl font-semibold text-[#20201d]">
            Level-Up Framework
          </h3>
          <p className="mt-4 text-[rgba(66,59,47,0.75)]">
            Replace traditional "closed-won" metrics with progressive maturity levels that evolve with your customer's growth journey.
          </p>
        </CardContent>
      </Card>
      <Card>
        <CardContent className="pt-6">
          <h3 className="text-xl font-semibold text-[#20201d]">
            Customer-Aligned Workflows
          </h3>
          <p className="mt-4 text-[rgba(66,59,47,0.75)]">
            Share real-time data and align your processes directly with customer workflows to drive meaningful consumption growth.
          </p>
        </CardContent>
      </Card>
      <Card>
        <CardContent className="pt-6">
          <h3 className="text-xl font-semibold text-[#20201d]">
            Impact Focused
          </h3>
          <p className="mt-4 text-[rgba(66,59,47,0.75)]">
            Move beyond measuring performance to actively affecting it. Get closer to your customers with tools built for lasting impact.
          </p>
        </CardContent>
      </Card>
    </div>
  </div>
</div>

<div className="bg-[#1f513f] text-white py-24 relative overflow-hidden">
  <div className="absolute inset-0 opacity-10">
  </div>
  <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative">
    <div className="lg:grid lg:grid-cols-2 lg:gap-16 items-center">
      <div>
        <h2 className="text-3xl font-bold">Why Belay?</h2>
        <p className="mt-6 text-lg text-white/90">
          A belay system allows climbers to support each other, enabling them to ascend to greater heights together. Like climbers using a belay system to reach new heights together, technology companies and their customers can achieve more through partnership. Our Progressive Success Approach and First Consumption Growth Platform enable this collaborative journey to success.
          </p>
        <div className="mt-12 grid grid-cols-1 gap-6">
          <div className="flex items-start gap-4">
            <div className="rounded-lg p-2 bg-white/10">
              <svg 
                className="w-6 h-6" 
                viewBox="0 0 24 24" 
                fill="none" 
                stroke="currentColor" 
                strokeWidth="2"
              >
                <path d="M13 20h-2V8l-5 5-1.4-1.4L12 4.2l7.4 7.4L18 13l-5-5z" />
              </svg>
            </div>
            <div>
              <h3 className="font-semibold">Progressive Success</h3>
              <p className="mt-2 text-white/80">
                Like climbers ascending together, our platform enables businesses and customers to achieve new heights of success through collaboration.
              </p>
            </div>
          </div>
          <div className="flex items-start gap-4">
            <div className="rounded-lg p-2 bg-white/10">
              <svg 
                className="w-6 h-6" 
                viewBox="0 0 24 24" 
                fill="none" 
                stroke="currentColor" 
                strokeWidth="2"
              >
                <path d="M18 20V10m-6 10V4M6 20v-6" />
              </svg>
            </div>
            <div>
              <h3 className="font-semibold">Mutual Growth</h3>
              <p className="mt-2 text-white/80">
                Just as climbing partners share the journey, we foster partnerships that drive predictable value for both parties.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-12 lg:mt-0">
        <div className="relative">
          <img
            src="/climbers.png"
            alt="Climbing illustration"
            className="w-full"
          />
        </div>
      </div>
    </div>
  </div>
</div>

<div className="bg-[#f4f2ed] py-24">
  <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
    <div className="lg:grid lg:grid-cols-2 lg:gap-16 items-center">
      <div>
        <h2 className="text-3xl font-bold text-[#20201d]">Sales ≠ Transactions</h2>
        <div className="mt-6 space-y-6">
          <div className="flex items-start gap-4">
            <div className="bg-[#1f513f] p-3 rounded-lg">
              <Users className="h-6 w-6 text-white" />
            </div>
            <div>
              <h3 className="font-semibold text-xl">Success is the Goal</h3>
              <p className="mt-2 text-[rgba(66,59,47,0.75)]">
                Active collaboration towards greater velocity and mutual success, not just tracking transactions.
              </p>
            </div>
          </div>
          <div className="flex items-start gap-4">
            <div className="bg-[#1f513f] p-3 rounded-lg">
              <LineChart className="h-6 w-6 text-white" />
            </div>
            <div>
              <h3 className="font-semibold text-xl">Real-Time Data Sharing</h3>
              <p className="mt-2 text-[rgba(66,59,47,0.75)]">
                Share fluid, organic data that evolves with progress, replacing static spreadsheets and delayed reporting.
              </p>
            </div>
          </div>
          <div className="flex items-start gap-4">
            <div className="bg-[#1f513f] p-3 rounded-lg">
              <Lock className="h-6 w-6 text-white" />
            </div>
            <div>
              <h3 className="font-semibold text-xl">Built on Trust</h3>
              <p className="mt-2 text-[rgba(66,59,47,0.75)]">
                Partnership is built from shared data and trust, not information asymmetry.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-12 lg:mt-0">
        <img
          src="/transformation.png"
          alt="Transformation illustration"
          className="rounded-lg shadow-xl"
        />
      </div>
    </div>
  </div>
</div>

{/* Level Up Section */}
<div className="bg-white py-24">
  <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
    <div className="text-center mb-16">
      <h2 className="text-3xl font-bold text-[#20201d]">It's Not "Closed Won", it's "Level-Up".</h2>
      <p className="mt-4 text-xl text-[rgba(66,59,47,0.75)]">
  Legacy CRMs end at "Closed Won." Consumption starts there.<br />
  Replace the traditional sales funnel with a progressive journey of growth.  
</p>
    </div>
    <div className="lg:grid lg:grid-cols-3 lg:gap-8">
      <Card>
        <CardContent className="pt-6">
          <div className="h-12 w-12 rounded-lg bg-[#1f513f] flex items-center justify-center mb-4">
            <Target className="h-6 w-6 text-white" />
          </div>
          <h3 className="text-xl font-semibold">Progressive Maturity</h3>
          <p className="mt-4 text-[rgba(66,59,47,0.75)]">
            Every use case evolves as customer workloads mature. There's no "end state" - only continuous growth.
          </p>
        </CardContent>
      </Card>
      <Card>
        <CardContent className="pt-6">
          <div className="h-12 w-12 rounded-lg bg-[#1f513f] flex items-center justify-center mb-4">
            <ArrowUpCircle className="h-6 w-6 text-white" />
          </div>
          <h3 className="text-xl font-semibold">Evolving Goals</h3>
          <p className="mt-4 text-[rgba(66,59,47,0.75)]">
            Align maturity levels with vendor and customer objectives for mutual growth and success.
          </p>
        </CardContent>
      </Card>
      <Card>
        <CardContent className="pt-6">
          <div className="h-12 w-12 rounded-lg bg-[#1f513f] flex items-center justify-center mb-4">
            <Activity className="h-6 w-6 text-white" />
          </div>
          <h3 className="text-xl font-semibold">Active Impact</h3>
          <p className="mt-4 text-[rgba(66,59,47,0.75)]">
          Build tailored maturity paths that enable your sales teams to seamlessly facilitate long term, value driven, growth.
          </p>
        </CardContent>
      </Card>
    </div>
  </div>
</div>

{/* Customer Alignment Section */}
<div className="bg-[#f4f2ed] py-24">
  <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
    <div className="lg:grid lg:grid-cols-2 lg:gap-16 items-center">
      <div className="order-2">
        <h2 className="text-3xl font-bold text-[#20201d]">Enable Your Field Teams</h2>
        <p className="mt-6 text-lg text-[rgba(66,59,47,0.75)]">
          Get closer to your customers with tools built for meaningful engagement
        </p>
        <div className="mt-8 space-y-6">
          <div className="flex items-center gap-4">
            <Check className="h-6 w-6 text-[#1f513f]" />
            <p>Increase meaningful, evergreen consumption</p>
          </div>
          <div className="flex items-center gap-4">
            <Check className="h-6 w-6 text-[#1f513f]" />
            <p>Better forecast upcoming performance</p>
          </div>
          <div className="flex items-center gap-4">
            <Check className="h-6 w-6 text-[#1f513f]" />
            <p>Reduce exposure to optimization and churn events</p>
          </div>
          <div className="flex items-center gap-4">
            <Check className="h-6 w-6 text-[#1f513f]" />
            <p>Gain insights into customer tool usage and success</p>
          </div>
        </div>
      </div>
      <div className="order-1 mb-12 lg:mb-0">
        <img
          src="/customer-alignment.svg"
          alt="Customer alignment illustration"
          className="rounded-lg shadow-xl"
        />
      </div>
    </div>
  </div>
</div>

{/* CTA Section */}
<div className="bg-[#1f513f] py-24">
  <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
    <h2 className="text-3xl font-bold text-white">
      Ready to Transform Your Customer Relationships?
    </h2>
    <p className="mt-4 text-xl text-white/80">
      Join the shift from transaction tracking to meaningful consumption growth.
    </p>
    <div className="mt-8">
      <Button
        onClick={() => auth.signinRedirect()}
        className="bg-white text-[#1f513f] hover:bg-[#f4f2ed] px-8 py-4 text-lg"
      >
        Get Started Today
      </Button>
    </div>
  </div>
</div>

      <footer className="bg-[#1f513f] text-white py-12">
  <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
    <div className="flex justify-center items-center space-x-4">
    <Link to="/terms" className="text-sm text-white hover:text-gray-300">
  Terms & Conditions
</Link>
      <span className="text-white/50">|</span>
      <Link to="/privacy" className="text-sm text-white hover:text-gray-300">
    Privacy Policy
  </Link>
      <span className="text-white/50">|</span>
      <button onClick={() => {}} className="text-sm text-white hover:text-gray-300">
        Support
      </button>
    </div>
    <div className="mt-8 border-t border-white/10 pt-8">
      <p className="text-sm text-center text-gray-300">
        © 2025 Belay. All rights reserved.
      </p>
    </div>
  </div>
</footer>
    </div>
  );
};

export default UnauthLanding;