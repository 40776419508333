import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { accountApi } from '../services/api';
import { Button } from '../components/ui/button';
import { useToast } from '../components/ui/use-toast';
import CreateAccountDialog from '../components/CreateAccountDialog';
import { Building2 } from 'lucide-react';
import AccountCard from '../components/AccountCard';

const Home = () => {
  const [expandedAccounts, setExpandedAccounts] = useState(new Set());
  const { toast } = useToast();
  const { 
    data: accounts = [], 
    isLoading, 
    error 
  } = useQuery('accounts', async () => {
    const data = await accountApi.getAccounts();
    return data || []; // Ensure we always return an array
  }, {
    onError: (error) => {
      console.error('Error fetching accounts:', error);
      toast({
        title: "Error",
        description: "Failed to load accounts",
        variant: "destructive"
      });
    }
  });

  // Update organizeAccounts to handle nested accounts recursively
  const organizeAccounts = (accounts) => {
    const accountMap = new Map(accounts.map(account => [account._id, { ...account, subAccounts: [] }]));
    const rootAccounts = [];

    accounts.forEach(account => {
      const mappedAccount = accountMap.get(account._id);
      if (account.parentAccount) {
        const parent = accountMap.get(account.parentAccount);
        if (parent) {
          parent.subAccounts.push(mappedAccount);
        }
      } else {
        rootAccounts.push(mappedAccount);
      }
    });

    return rootAccounts;
  };

  // Safely handle account creation success
  const handleAccountCreated = (newAccount) => {
    if (newAccount && newAccount._id) {
      // Handle successful account creation
      // You might want to navigate to the new account or update the UI
    }
  };

  const toggleAccount = (accountId, event) => {
    event.preventDefault(); // Prevent navigation when clicking toggle
    setExpandedAccounts(current => {
      const newSet = new Set(current);
      if (newSet.has(accountId)) {
        newSet.delete(accountId);
      } else {
        newSet.add(accountId);
      }
      return newSet;
    });
  };

  if (isLoading) return (
    <div className="flex items-center justify-center min-h-[50vh]">
      <div className="text-lg">Loading accounts...</div>
    </div>
  );

  if (error) return (
    <div className="text-red-500">
      Error loading accounts: {error.message}
    </div>
  );

  const organizedAccounts = organizeAccounts(accounts);

  // Recursive component for rendering account hierarchy
  const AccountHierarchy = ({ account, level = 0 }) => {
    return (
      <div className="space-y-4">
        <AccountCard
          account={account}
          isExpanded={expandedAccounts.has(account._id)}
          onToggle={toggleAccount}
          isSubAccount={level > 0}
        />
        
        {account.subAccounts?.length > 0 && expandedAccounts.has(account._id) && (
          <div className={`grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 ml-6`}>
            {account.subAccounts.map(subAccount => (
              <div key={subAccount._id}>
                <AccountHierarchy account={subAccount} level={level + 1} />
              </div>
            ))}
          </div>
        )}
      </div>
    );
  };

  return (
    // Add responsive padding
    <div className="space-y-6 p-4 md:p-6">
      {/* Make header responsive */}
      <div className="flex flex-col space-y-4 sm:flex-row sm:justify-between sm:items-center">
        <div>
          <h1 className="text-2xl md:text-3xl font-bold">Accounts</h1>
          <p className="text-gray-500 mt-1">Manage your customer accounts and their workspaces</p>
        </div>
        <CreateAccountDialog onSuccess={handleAccountCreated} />
      </div>

      {/* Make account cards responsive */}
      <div className="space-y-4">
        {organizedAccounts.map(account => (
          <AccountHierarchy key={account._id} account={account} />
        ))}
      </div>

      {/* Update empty state to be responsive */}
      {accounts?.length === 0 && (
        <div className="text-center py-6 md:py-10">
          <Building2 className="w-12 h-12 mx-auto text-gray-400 mb-4" />
          <p className="text-gray-500">No accounts created yet.</p>
          <Button className="mt-4">
            Create your first account
          </Button>
        </div>
      )}
    </div>
  );
};

export default Home;