import React, { useState } from 'react';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogFooter } from '../../ui/dialog';
import { Input } from '../../ui/input';
import { Label } from '../../ui/label';
import { Button } from '../../ui/button';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../../ui/select';
import { Checkbox } from '../../ui/checkbox';
import { Trash2 } from 'lucide-react';

const FieldEditor = ({ isOpen, onClose, onSave, initialData = null }) => {
  const [field, setField] = useState({
    key: '',
    label: '',
    type: 'text',
    required: false,
    options: [],
    ...initialData
  });

  const handleSave = () => {
    onSave(field);
    onClose();
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>
            {initialData ? 'Edit Field' : 'Add New Field'}
          </DialogTitle>
        </DialogHeader>
        
        <div className="space-y-4 py-4">
        <div className="space-y-4 py-4">
  <div className="space-y-2">
    <Label>Field Label</Label>
    <Input
      value={field.label}
      onChange={(e) => {
        const label = e.target.value;
        setField(prev => ({
          ...prev,
          label,
          key: label.toLowerCase().replace(/\s+/g, '_')
        }));
      }}
      placeholder="Enter field label"
    />
  </div>

  <div className="space-y-2">
    <Label>Field Type</Label>
    <Select
      value={field.type}
      onValueChange={(value) => setField(prev => ({ ...prev, type: value }))}
    >
      <SelectTrigger>
        <SelectValue placeholder="Select field type" />
      </SelectTrigger>
      <SelectContent>
        <SelectItem value="text">Text</SelectItem>
        <SelectItem value="textarea">Long Text</SelectItem>
        <SelectItem value="number">Number</SelectItem>
        <SelectItem value="date">Date</SelectItem>
        <SelectItem value="select">Dropdown</SelectItem>
        <SelectItem value="boolean">Yes/No</SelectItem>
      </SelectContent>
    </Select>
  </div>

  {field.type === 'select' && (
    <div className="space-y-2">
      <Label>Options</Label>
      <div className="space-y-2">
        {field.options.map((option, index) => (
          <div key={index} className="flex gap-2">
            <Input
              value={option.label}
              onChange={(e) => {
                const newOptions = [...field.options];
                newOptions[index] = {
                  label: e.target.value,
                  value: e.target.value.toLowerCase().replace(/\s+/g, '_')
                };
                setField(prev => ({ ...prev, options: newOptions }));
              }}
              placeholder="Option label"
            />
            <Button
              variant="ghost"
              size="sm"
              onClick={() => {
                const newOptions = [...field.options];
                newOptions.splice(index, 1);
                setField(prev => ({ ...prev, options: newOptions }));
              }}
            >
              <Trash2 className="h-4 w-4" />
            </Button>
          </div>
        ))}
        <Button
          variant="outline"
          size="sm"
          onClick={() => {
            setField(prev => ({
              ...prev,
              options: [...prev.options, { label: '', value: '' }]
            }));
          }}
        >
          Add Option
        </Button>
      </div>
    </div>
  )}

  <div className="flex items-center space-x-2">
    <Checkbox
      id="required"
      checked={field.required}
      onCheckedChange={(checked) => 
        setField(prev => ({ ...prev, required: checked }))
      }
    />
    <Label htmlFor="required">Required field</Label>
  </div>

  {field.type !== 'boolean' && (
    <div className="space-y-2">
      <Label>Default Value</Label>
      <Input
        value={field.defaultValue || ''}
        onChange={(e) => setField(prev => ({ 
          ...prev, 
          defaultValue: e.target.value 
        }))}
        placeholder="Enter default value"
      />
    </div>
  )}

  <div className="space-y-2">
    <Label>Help Text</Label>
    <Input
      value={field.helpText || ''}
      onChange={(e) => setField(prev => ({ 
        ...prev, 
        helpText: e.target.value 
      }))}
      placeholder="Enter help text for this field"
    />
  </div>

  {(field.type === 'text' || field.type === 'textarea') && (
        <div className="space-y-2">
      <Label>Validation Pattern (Optional)</Label>
      <Input
        value={field.validation?.pattern || ''}
        onChange={(e) => setField(prev => ({
          ...prev,
          validation: {
            ...prev.validation,
            pattern: e.target.value
          }
        }))}
        placeholder="Regular expression pattern"
      />
      <p className="text-sm text-gray-500">
        Enter a regular expression to validate input
      </p>
    </div>
  )}

  {field.type === 'number' && (
    <div className="grid grid-cols-2 gap-4">
      <div className="space-y-2">
        <Label>Minimum Value</Label>
        <Input
          type="number"
          value={field.validation?.min || ''}
          onChange={(e) => setField(prev => ({
            ...prev,
            validation: {
              ...prev.validation,
              min: e.target.value
            }
          }))}
        />
      </div>
      <div className="space-y-2">
        <Label>Maximum Value</Label>
        <Input
          type="number"
          value={field.validation?.max || ''}
          onChange={(e) => setField(prev => ({
            ...prev,
            validation: {
              ...prev.validation,
              max: e.target.value
            }
          }))}
        />
      </div>
    </div>
  )}
</div>        </div>

        <DialogFooter>
          <Button variant="outline" onClick={onClose}>
            Cancel
          </Button>
          <Button onClick={handleSave}>
            Save Field
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default FieldEditor;