// Board.js
import React, { useState, useEffect, useCallback } from 'react';
import { DragDropContext } from '@hello-pangea/dnd';
import Column from './Column';
import AIChat from '../AIChat';
import MaturityProgress from './MaturityProgress';
import { boardApi, taskApi } from '../../services/api';
import { Button } from '../../components/ui/button';
import { Plus, Filter, MessageSquare } from 'lucide-react';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogFooter } from '../../components/ui/dialog';
import { Input } from '../../components/ui/input';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../../components/ui/select';
import { Textarea } from '../../components/ui/textarea';
import { cn } from '../../lib/utils';
import TaskSelector from '../TaskSelector';

const Board = ({ boardId }) => {
  
  const [showAIChat, setShowAIChat] = useState(false);
  const [board, setBoard] = useState(null);
  const [tasks, setTasks] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showColumnDialog, setShowColumnDialog] = useState(false);
  const [newColumnTitle, setNewColumnTitle] = useState('');
  const [showTaskDialog, setShowTaskDialog] = useState(false);
  const [newTask, setNewTask] = useState({
    title: '',
    description: '',
    type: '',
    status: '',
    priority: 'MEDIUM'
  });

  const loadBoard = useCallback(async () => {
    try {
      setLoading(true);
      const boardData = await boardApi.getBoardById(boardId);
      setBoard(boardData);
      
      // Initialize empty arrays for all columns
      const initialTasks = boardData.columns.reduce((acc, column) => {
        acc[column.id] = [];
        return acc;
      }, {});

      console.log('Board data loaded:', boardData);
console.log('Template data:', boardData.template);
      
      // Organize tasks into their respective columns
      const organizedTasks = (boardData.tasks || []).reduce((acc, task) => {
        const status = task.status || boardData.columns[0]?.id;
        const taskWithStringId = {
          ...task,
          _id: String(task._id)
        };
        
        // Ensure the status exists in our columns
        if (acc.hasOwnProperty(status)) {
          acc[status].push(taskWithStringId);
        } else {
          // If status doesn't exist, put in first column
          acc[boardData.columns[0]?.id]?.push(taskWithStringId);
        }
        return acc;
      }, initialTasks);
      
      setTasks(organizedTasks);
    } catch (err) {
      console.error('Error loading board:', err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  }, [boardId]);

  useEffect(() => {
    loadBoard();
  }, [loadBoard]);

  const onDragEnd = async (result) => {
    const { source, destination, draggableId } = result;
  
    if (!destination) return;
    if (
      source.droppableId === destination.droppableId &&
      source.index === destination.index
    ) {
      return;
    }
  
    // Store arrays at this scope so they're available for error handling
    const sourceArray = Array.isArray(tasks[source.droppableId]) 
      ? [...tasks[source.droppableId]]  // Create a copy
      : [];
    const destArray = Array.isArray(tasks[destination.droppableId])
      ? [...tasks[destination.droppableId]]  // Create a copy
      : [];
  
    try {
      // Find the task being moved
      const movedTask = sourceArray[source.index];
      
      // Remove task from source array
      const newSourceArray = [...sourceArray];
      newSourceArray.splice(source.index, 1);
      
      // Add task to destination array with updated status
      const updatedTask = {
        ...movedTask,
        status: destination.droppableId
      };
  
      const newDestArray = [...destArray];
      newDestArray.splice(destination.index, 0, updatedTask);
  
      // Update state optimistically
      setTasks(prevTasks => ({
        ...prevTasks,
        [source.droppableId]: newSourceArray,
        [destination.droppableId]: newDestArray
      }));
  
      // Update backend
      const updatedTaskFromServer = await taskApi.updateTask(draggableId, {
        status: destination.droppableId,
        title: movedTask.title,
        description: movedTask.description,
        type: movedTask.type
      });
  
      // Update state with server response to ensure sync
      setTasks(prevTasks => {
        const newTasks = { ...prevTasks };
        // Remove from source if it's still there
        newTasks[source.droppableId] = newTasks[source.droppableId].filter(
          task => task._id !== draggableId
        );
        // Update in destination with server data
        newTasks[destination.droppableId] = newTasks[destination.droppableId].map(task =>
          task._id === draggableId ? { ...updatedTaskFromServer, _id: String(updatedTaskFromServer._id) } : task
        );
        return newTasks;
      });
  
    } catch (err) {
      console.error('Error in drag and drop:', err);
      // Only reload the board if there's a serious error
      if (err.response?.status === 404 || err.response?.status === 500) {
        await loadBoard();
      } else {
        // Revert to original state for other errors
        setTasks(prevTasks => ({
          ...prevTasks,
          [source.droppableId]: sourceArray,
          [destination.droppableId]: destArray
        }));
      }
    }
  };

  const handleColumnUpdate = async (columnId, newTitle) => {
    try {
      setBoard(prevBoard => ({
        ...prevBoard,
        columns: prevBoard.columns.map(col =>
          col.id === columnId ? { ...col, title: newTitle } : col
        )
      }));
    } catch (error) {
      console.error('Error updating column:', error);
      loadBoard();
    }
  };

  const handleTaskUpdate = async (updatedTask) => {
    setTasks(prevTasks => {
      const newTasks = { ...prevTasks };
      
      // Find which column the task is currently in
      let currentColumnId = null;
      Object.entries(newTasks).forEach(([columnId, columnTasks]) => {
        if (columnTasks.some(t => t._id === updatedTask._id)) {
          currentColumnId = columnId;
        }
      });
  
      if (currentColumnId) {
        // If the task is updating but not changing columns
        if (currentColumnId === updatedTask.status) {
          newTasks[currentColumnId] = newTasks[currentColumnId].map(task =>
            task._id === updatedTask._id ? updatedTask : task
          );
        } else {
          // If the task is changing columns
          // Remove from old column
          newTasks[currentColumnId] = newTasks[currentColumnId].filter(
            task => task._id !== updatedTask._id
          );
          
          // Add to new column
          if (!newTasks[updatedTask.status]) {
            newTasks[updatedTask.status] = [];
          }
          newTasks[updatedTask.status].push(updatedTask);
        }
      }
      
      return newTasks;
    });
  };

  const handleTaskDelete = async (taskId) => {
    setTasks(prevTasks => {
      const newTasks = { ...prevTasks };
      Object.keys(newTasks).forEach(columnId => {
        newTasks[columnId] = newTasks[columnId].filter(task => 
          task._id !== taskId
        );
      });
      return newTasks;
    });
  };

  const handleAddColumn = async () => {
    try {
      const newColumn = {
        id: newColumnTitle.toLowerCase().replace(/\s+/g, '-'),
        title: newColumnTitle,
        order: board.columns.length
      };

      await boardApi.updateBoard(boardId, {
        columns: [...board.columns, newColumn]
      });

      setShowColumnDialog(false);
      setNewColumnTitle('');
      await loadBoard();
    } catch (err) {
      console.error('Error adding column:', err);
      setError(err.message);
    }
  };

  const handleAddTask = async () => {
    try {
      // Create the task
      const task = await taskApi.createTask({
        ...newTask,
        boardId
      });

      // Update local state
      setTasks(prevTasks => {
        const status = task.status || board.columns[0]?.id;
        return {
          ...prevTasks,
          [status]: [...(prevTasks[status] || []), task]
        };
      });

      // Reset form and close dialog
      setNewTask({
        title: '',
        description: '',
        type: '',
        status: '',
        priority: 'MEDIUM'
      });
      setShowTaskDialog(false);
    } catch (error) {
      console.error('Error creating task:', error);
      setError('Failed to create task');
    }
  };

  const handleColumnDelete = async (columnId) => {
    try {
      setBoard(prevBoard => ({
        ...prevBoard,
        columns: prevBoard.columns.filter(col => col.id !== columnId)
      }));

        // Remove tasks from the deleted column
    setTasks(prevTasks => {
      const { [columnId]: deletedColumn, ...remainingTasks } = prevTasks;
      return remainingTasks;
    });
  } catch (error) {
    console.error('Error deleting column:', error);
    loadBoard(); // Reload board on error
  }
};

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div className="h-full flex flex-col bg-gray-50">
        <div className="bg-white shadow-sm px-6 py-4 border-b">
        {board?.template && (
  <MaturityProgress 
  boardId={boardId} 
  accountId={board.accountId} 
/>
)}
  <div className="flex items-center gap-6">
  <div className="flex items-center gap-2">
    {/* Add Column button - using secondary style */}
    <Button 
  variant="green"
  size="sm" 
  onClick={() => setShowColumnDialog(true)}
>
      <Plus className="h-4 w-4 mr-2" />
      Add Column
    </Button>

    {/* Add Task button - using brand style */}
    <Button 
  variant="green"
  size="sm" 
  onClick={() => setShowTaskDialog(true)}
>
      <Plus className="h-4 w-4 mr-2" />
      Add Task
    </Button>

    {/* TaskSelector component would remain as is since it's a separate component */}
    <TaskSelector 
  boardId={boardId}
  onTasksSelected={loadBoard}
  exclude={Object.values(tasks).flat().map(t => t._id)}
  className="button button-secondary flex items-center"
/>

    {/* Filter button - using secondary style */}
    <Button 
  variant="canyon-dark"
  size="sm" 
  onClick={() => setShowColumnDialog(true)}
>
      <Filter className="h-4 w-4 mr-2" />
      Filter
    </Button>
  </div>

  <div className="flex items-center">
    {/* AI Assistant button - already updated with brand colors */}
    <Button
      className="button button-brand flex items-center"
      size="sm"
      onClick={() => setShowAIChat(!showAIChat)}
    >
      <MessageSquare className="h-4 w-4 mr-2" />
      {showAIChat ? 'Hide AI Assistant' : 'Show AI Assistant'}
    </Button>
  </div>
</div>
        </div>

        <div className={cn(
          "flex-1 relative",
          showAIChat ? 'mr-[400px]' : ''
        )}>
          <div className="overflow-x-auto py-6 h-full">
            <div className="flex gap-4 h-full">
              {board?.columns.sort((a, b) => a.order - b.order).map((column) => (
                <Column
                  key={column.id}
                  droppableId={column.id}
                  title={column.title}
                  tasks={tasks[column.id] || []}
                  boardId={boardId}
                  onColumnUpdate={handleColumnUpdate}
                  onTaskUpdate={handleTaskUpdate}
                  onTaskDelete={handleTaskDelete}
                  onColumnDelete={handleColumnDelete}
                  allColumns={board.columns}
                />
              ))}
            </div>
          </div>
          {/* AI Chat Sidebar */}
          <div 
            className={cn(
              "fixed right-0 top-16 bottom-0 w-[400px] bg-white border-l border-gray-200 transform transition-transform duration-200 ease-in-out z-10",
              showAIChat ? 'translate-x-0' : 'translate-x-full'
            )}
          >
            <div className="h-full">
              <AIChat 
                boardId={boardId}
                onTaskUpdate={loadBoard}
              />
            </div>
          </div>
        </div>

        <Dialog open={showTaskDialog} onOpenChange={setShowTaskDialog}>
          <DialogContent className="sm:max-w-[500px] bg-white">
            <DialogHeader>
              <DialogTitle>Add New Task</DialogTitle>
            </DialogHeader>
            <div className="grid gap-4 py-4">
              <div className="grid gap-2">
                <label htmlFor="title" className="text-sm font-medium">
                  Title
                </label>
                <Input
                  id="title"
                  value={newTask.title}
                  onChange={(e) => setNewTask(prev => ({
                    ...prev,
                    title: e.target.value
                  }))}
                  placeholder="Task title"
                />
              </div>
              <div className="grid gap-2">
                <label htmlFor="description" className="text-sm font-medium">
                  Description
                </label>
                <Textarea
                  id="description"
                  value={newTask.description}
                  onChange={(e) => setNewTask(prev => ({
                    ...prev,
                    description: e.target.value
                  }))}
                  placeholder="Task description"
                />
              </div>
              <div className="grid grid-cols-2 gap-4">
                <div className="grid gap-2">
                  <label htmlFor="type" className="text-sm font-medium">
                    Type
                  </label>
                  <Select
                    value={newTask.type}
                    onValueChange={(value) => setNewTask(prev => ({
                      ...prev,
                      type: value
                    }))}
                  >
                    <SelectTrigger>
                      <SelectValue placeholder="Select type" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem value="Business">Business</SelectItem>
                      <SelectItem value="Workload">Workload</SelectItem>
                      <SelectItem value="Platform">Platform</SelectItem>
                      <SelectItem value="Product">Product</SelectItem>
                      <SelectItem value="Enablement">Enablement</SelectItem>
                      <SelectItem value="Technical">Technical</SelectItem>
                    </SelectContent>
                  </Select>
                </div>
                <div className="grid gap-2">
                  <label htmlFor="status" className="text-sm font-medium">
                    Status
                  </label>
                  <Select
                    value={newTask.status}
                    onValueChange={(value) => setNewTask(prev => ({
                      ...prev,
                      status: value
                    }))}
                  >
                    <SelectTrigger>
                      <SelectValue placeholder="Select status" />
                    </SelectTrigger>
                    <SelectContent>
                      {board?.columns.map(column => (
                        <SelectItem key={column.id} value={column.id}>
                          {column.title}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </div>
              </div>
              <div className="grid gap-2">
                <label htmlFor="priority" className="text-sm font-medium">
                  Priority
                </label>
                <Select
                  value={newTask.priority}
                  onValueChange={(value) => setNewTask(prev => ({
                    ...prev,
                    priority: value
                  }))}
                >
                  <SelectTrigger>
                    <SelectValue placeholder="Select priority" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="LOW">Low</SelectItem>
                    <SelectItem value="MEDIUM">Medium</SelectItem>
                    <SelectItem value="HIGH">High</SelectItem>
                    <SelectItem value="URGENT">Urgent</SelectItem>
                  </SelectContent>
                </Select>
              </div>
            </div>
            <DialogFooter>
              <Button variant="outline" onClick={() => setShowTaskDialog(false)}>
                Cancel
              </Button>
              <Button 
                onClick={handleAddTask}
                disabled={!newTask.title || !newTask.type || !newTask.status}
              >
                Create Task
              </Button>
            </DialogFooter>
          </DialogContent>
        </Dialog>

        <Dialog open={showColumnDialog} onOpenChange={setShowColumnDialog}>
          <DialogContent className="bg-white">
            <DialogHeader>
              <DialogTitle>Add New Column</DialogTitle>
            </DialogHeader>
            <div className="py-4">
              <Input
                placeholder="Column Title"
                value={newColumnTitle}
                onChange={(e) => setNewColumnTitle(e.target.value)}
              />
            </div>
            <DialogFooter>
              <Button variant="outline" onClick={() => setShowColumnDialog(false)}>
                Cancel
              </Button>
              <Button onClick={handleAddColumn} disabled={!newColumnTitle}>
                Add Column
              </Button>
            </DialogFooter>
          </DialogContent>
        </Dialog>
      </div>
    </DragDropContext>
  );
};

export default Board;