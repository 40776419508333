import React from 'react';
import { Card, CardContent, CardHeader, CardTitle } from '../ui/card';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '../ui/dialog';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '../ui/tabs';
import { BarChart, Bar, PieChart, Pie, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { TrendingUp, TrendingDown, Shield } from 'lucide-react';

const COLORS = ['#1f513f', '#2a6f56', '#358d6d', '#40ab84', '#4bc99b'];

const MetricsDrillDownDialog = ({ 
  isOpen, 
  onClose, 
  metrics, 
  initialTab = 'revenue',
  title = 'Business Value Analysis'
}) => {
  const [activeTab, setActiveTab] = React.useState(initialTab);

  // Calculate metrics totals (reuse your existing calculations)
  const metricsData = React.useMemo(() => ({
    revenue: {
      total: metrics.reduce((sum, w) => {
        const valueMap = {
          '100k': 100000,
          '500k': 500000,
          '1m': 1000000,
          '5m': 5000000,
          '5m_plus': 7500000
        };
        return sum + (valueMap[w.revenueImpact] || 0);
      }, 0),
      opportunities: metrics.filter(w => w.revenueImpact).length
    },
    cost: {
      total: metrics.reduce((sum, w) => {
        const valueMap = {
          '50k': 50000,
          '200k': 200000,
          '500k': 500000,
          '1m': 1000000,
          '1m_plus': 1500000
        };
        return sum + (valueMap[w.costSavings] || 0);
      }, 0),
      opportunities: metrics.filter(w => w.costSavings).length
    },
    risk: {
      critical: metrics.filter(w => w.riskSeverity === 'critical').length,
      high: metrics.filter(w => w.riskSeverity === 'high').length,
      medium: metrics.filter(w => w.riskSeverity === 'medium').length,
      low: metrics.filter(w => w.riskSeverity === 'low').length
    }
  }), [metrics]);

  const renderRevenueContent = () => {
    // Format revenue drivers data
    const revenueData = Object.entries(
      metrics.reduce((acc, metric) => {
        if (metric.revenueDriver) {
          const label = metric.revenueDriver
            .split('_')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
          acc[label] = (acc[label] || 0) + 1;
        }
        return acc;
      }, {})
    ).map(([name, value]) => ({ name, value }));

    return (
      <div className="space-y-6">
        <div className="grid grid-cols-2 gap-4">
          <Card>
            <CardContent className="pt-6">
              <div className="flex items-center gap-2">
                <TrendingUp className="w-5 h-5 text-emerald-600" />
                <div>
                  <div className="text-2xl font-bold">
                    ${(metricsData.revenue.total / 1000000).toFixed(1)}M
                  </div>
                  <div className="text-sm text-gray-500">
                    Total Revenue Potential
                  </div>
                </div>
              </div>
            </CardContent>
          </Card>
          
          <Card>
            <CardContent className="pt-6">
              <div className="flex items-center gap-2">
                <div className="text-2xl font-bold">
                  {metricsData.revenue.opportunities}
                </div>
                <div className="text-sm text-gray-500">
                  Revenue Opportunities
                </div>
              </div>
            </CardContent>
          </Card>
        </div>

        <Card className="p-4">
          <CardHeader>
            <CardTitle>Revenue Drivers Distribution</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="h-96">
              <ResponsiveContainer width="100%" height="100%">
                <PieChart>
                  <Pie
                    data={revenueData}
                    dataKey="value"
                    nameKey="name"
                    cx="50%"
                    cy="50%"
                    outerRadius={120}
                    label
                  >
                    {revenueData.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                  </Pie>
                  <Tooltip />
                  <Legend />
                </PieChart>
              </ResponsiveContainer>
            </div>
          </CardContent>
        </Card>

        <Card className="p-4">
          <CardHeader>
            <CardTitle>Revenue Distribution by Board</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="h-96">
              <ResponsiveContainer width="100%" height="100%">
                <BarChart 
                  data={metrics
                    .filter(m => m.revenueImpact)
                    .map(m => ({
                      name: m.boardName || 'Unnamed Board',
                      revenue: parseInt(m.revenueImpact.replace(/[^0-9]/g, '')) * 1000
                    }))}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip 
                    formatter={(value) => `$${(value/1000).toFixed(0)}k`}
                  />
                  <Bar dataKey="revenue" fill="#1f513f">
                    {metrics.map((_, index) => (
                      <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
            </div>
          </CardContent>
        </Card>
      </div>
    );
  };

  // Similar implementations for cost and risk tabs...
  // (I've omitted them for brevity but they would follow the same pattern)

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="max-w-4xl h-[90vh]">
        <DialogHeader>
          <DialogTitle>{title}</DialogTitle>
        </DialogHeader>
        
        <div className="overflow-y-auto flex-1">
          <Tabs value={activeTab} onValueChange={setActiveTab}>
            <TabsList className="mb-6">
              <TabsTrigger value="revenue" className="flex items-center gap-2">
                <TrendingUp className="w-4 h-4" />
                Revenue Growth
              </TabsTrigger>
              <TabsTrigger value="cost" className="flex items-center gap-2">
                <TrendingDown className="w-4 h-4" />
                Cost Reduction
              </TabsTrigger>
              <TabsTrigger value="risk" className="flex items-center gap-2">
                <Shield className="w-4 h-4" />
                Risk Analysis
              </TabsTrigger>
            </TabsList>

            <TabsContent value="revenue">
              {renderRevenueContent()}
            </TabsContent>

            <TabsContent value="cost">
              {/* Cost content */}
            </TabsContent>

            <TabsContent value="risk">
              {/* Risk content */}
            </TabsContent>
          </Tabs>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default MetricsDrillDownDialog;