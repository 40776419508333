import { useState } from 'react';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { CognitoApi, accountApi } from '../../services/api';
import { useAuth } from "react-oidc-context";
import { refreshTokenSilently } from '../../utils/auth';

import {
  Table,
  TableHeader,
  TableBody,
  TableRow,
  TableHead,
  TableCell
} from '../../components/ui/table';
import { Input } from '../../components/ui/input';
import { Button } from '../../components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
  DialogDescription,
} from '../../components/ui/dialog';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '../../components/ui/select';
import { useToast } from '../../components/ui/use-toast';
import { CheckCircle2, Search, UserPlus, UserX } from 'lucide-react';
import { AlertTriangle, Trash2 } from 'lucide-react';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "../../components/ui/alert-dialog";

const AccountManagement = () => {
  const auth = useAuth();
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedGroup, setSelectedGroup] = useState('');
  const [showAddDialog, setShowAddDialog] = useState(false);
  const [showCreateGroupDialog, setShowCreateGroupDialog] = useState(false);
  const [newGroupName, setNewGroupName] = useState('');
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const queryClient = useQueryClient();
  const { toast } = useToast();

  // Get current user's ID
  const currentUserId = auth.user?.profile["cognito:username"];

  // list groups
  const { data: groups = [], error: groupsError, isLoading: groupsLoading } = useQuery(
    'groups',
    async () => {
      const data = await CognitoApi.listGroups();
      return Array.isArray(data) 
        ? data
            .filter(group => group.name.startsWith('Org_'))
            .map(group => ({
              ...group,
              accountId: group.name.split('Org_')[1],
              description: group.Description
            }))
        : [];
    }
  );

  // Add query for account names
  const { data: accountNames = {} } = useQuery(
    ['accountNames', groups],
    async () => {
      if (!groups.length) return {};
      const accountIds = groups.map(group => group.accountId);
      return await accountApi.getAccountNames(accountIds);
    },
    {
      enabled: groups.length > 0
    }
  );

  // list users in selected group
  const { data: groupUsers = [], error: groupUsersError, isLoading: groupUsersLoading } = useQuery(
    ['groupUsers', selectedGroup],
    async () => {
      if (!selectedGroup) return [];
      const data = await CognitoApi.listGroupUsers(selectedGroup);
      return Array.isArray(data) ? data : [];
    },
    {
      enabled: !!selectedGroup,
    }
  );

  // Fetch all users not in group for add dialog
  const { data: availableUsers = [] } = useQuery(
    ['available-users', selectedGroup],
    async () => {
      if (!selectedGroup) return [];
      const data = await CognitoApi.listAvailableUsers(selectedGroup);
      return data;
    },
    {
      enabled: !!selectedGroup && showAddDialog
    }
  );

  // Add user to group mutation
  const addUserMutation = useMutation(
    async ({ userId, groupId }) => {
      const response = await CognitoApi.addUsertoGroup(groupId, userId);
      return response;
    },
    {
      onSuccess: async (_, { userId }) => {
        // Only refresh token if current user is being added
        if (userId === currentUserId) {
          try {
            await refreshTokenSilently(auth);
          } catch (error) {
            console.error('Failed to refresh token:', error);
          }
        }
        
        queryClient.invalidateQueries(['groupUsers', selectedGroup]);
        queryClient.invalidateQueries(['availableUsers', selectedGroup]);
        toast({
          title: 'User added successfully',
          description: 'The user has been added to the group',
        });
        setShowAddDialog(false);
      },
      onError: (error) => {
        toast({
          title: 'Error adding user',
          description: error.message,
          variant: 'destructive',
        });
      },
    }
  );

  // Remove user from group mutation
  const removeUserMutation = useMutation(
    async ({ userId, groupId }) => {
      const response = await CognitoApi.removeUserFromGroup(groupId, userId);
      return response;
    },
    {
      onSuccess: async (_, { userId }) => {
        // Only refresh token if current user is being removed
        if (userId === currentUserId) {
          try {
            await refreshTokenSilently(auth);
          } catch (error) {
            console.error('Failed to refresh token:', error);
          }
        }

        queryClient.invalidateQueries(['groupUsers', selectedGroup]);
        toast({
          title: 'User removed successfully',
          description: 'The user has been removed from the group',
        });
      },
      onError: (error) => {
        toast({
          title: 'Error removing user',
          description: error.message,
          variant: 'destructive',
        });
      },
    }
  );

  // Create new group mutation
  const createGroupMutation = useMutation(
    async (groupName) => {
      const response = await CognitoApi.createGroup(groupName);
      return response;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('groups');
        toast({
          title: 'Group created successfully',
          description: 'The new group has been created',
        });
        setShowCreateGroupDialog(false);
        setNewGroupName('');
      },
      onError: (error) => {
        toast({
          title: 'Error creating group',
          description: error.message,
          variant: 'destructive',
        });
      },
    }
  );

  // Add delete group mutation
  const deleteGroupMutation = useMutation(
    async (groupId) => {
      return await CognitoApi.deleteGroup(groupId);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('groups');
        toast({
          title: 'Group deleted successfully',
          description: 'The orphaned group has been removed',
        });
        setSelectedGroup('');
      },
      onError: (error) => {
        toast({
          title: 'Error deleting group',
          description: error.message,
          variant: 'destructive',
        });
      },
    }
  );

  // Helper to check if group is orphaned
  const isOrphanedGroup = (groupId) => {
    return !accountNames[groupId]?.name;
  };

  // Filter users based on search term
  const filteredGroupUsers = groupUsers.filter((user) =>
    user.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    user.email.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Add loading and error states
  if (groupsLoading) return <div>Loading groups...</div>;
  if (groupsError) return <div>Error loading groups: {groupsError.message}</div>;
  if (groupUsersLoading) return <div>Loading group users...</div>;
  if (groupUsersError) return <div>Error loading group users: {groupUsersError.message}</div>;

  return (
    <div className="p-8 max-w-6xl mx-auto space-y-6">
      <div className="flex justify-between items-center">
        <h1 className="text-2xl font-bold">Account Access</h1>
        <div className="flex space-x-4">
          <Select value={selectedGroup} onValueChange={setSelectedGroup}>
            <SelectTrigger className="w-[300px]">
              <SelectValue placeholder="Select an account" />
            </SelectTrigger>
            <SelectContent>
              {groups
                .filter(group => !(!accountNames[group.accountId]?.name && group.description))
                .map((group) => (
                <SelectItem key={group.name} value={group.name}>
                  {(group.name || group.description) ? (
                    <div className="flex flex-col">
                      <span>{accountNames[group.accountId]?.name || group.name}</span>
                    </div>
                  ) : (
                    <div className="flex flex-col">
                    <span className="flex items-center text-yellow-600">
                      <AlertTriangle className="w-4 h-4 mr-2" />
                      Orphaned Group ({group.accountId})
                    </span>
                      <span className="text-sm text-gray-500">{group.description || 'No description'}</span>
                    </div>
                  )}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
          
          {selectedGroup && isOrphanedGroup(selectedGroup.split('Org_')[1]) && (
            <Button 
              variant="destructive" 
              onClick={() => setShowDeleteDialog(true)}
              disabled={deleteGroupMutation.isLoading}
            >
              <Trash2 className="w-4 h-4 mr-2" />
              Delete Orphaned Group
            </Button>
          )}
        </div>
      </div>

      {/* Add Alert Dialog for delete confirmation */}
      <AlertDialog open={showDeleteDialog} onOpenChange={setShowDeleteDialog}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Delete Orphaned Group</AlertDialogTitle>
            <AlertDialogDescription>
              Are you sure you want to delete this orphaned group? This action cannot be undone.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <AlertDialogAction
              className="bg-red-600 hover:bg-red-700"
              onClick={() => {
                deleteGroupMutation.mutate(selectedGroup);
                setShowDeleteDialog(false);
              }}
            >
              Delete
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>

      {selectedGroup && (
        <>
          <div className="flex items-center justify-between">
            <div className="flex items-center space-x-2">
              <Search className="w-4 h-4 text-gray-400" />
              <Input
                placeholder="Search users..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="w-64"
              />
            </div>
            <Button onClick={() => setShowAddDialog(true)}>
              <UserPlus className="w-4 h-4 mr-2" />
              Add User
            </Button>
          </div>

          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>Name</TableHead>
                <TableHead>Email</TableHead>
                <TableHead>Status</TableHead>
                <TableHead>Joined</TableHead>
                <TableHead>Actions</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {filteredGroupUsers.map((user) => (
                <TableRow key={user.userId}>
                  <TableCell className="font-medium">{user.name}</TableCell>
                  <TableCell>{user.email}</TableCell>
                  <TableCell>
                    <div className="flex items-center">
                      <CheckCircle2 className="w-4 h-4 text-green-500 mr-2" />
                      {user.Enabled ? 'Active' : 'Inactive'}
                    </div>
                  </TableCell>
                  <TableCell>
                    {new Date(user.joined).toLocaleDateString()}
                  </TableCell>
                  <TableCell>
                    <Button
                      variant="ghost"
                      size="sm"
                      onClick={() =>
                        removeUserMutation.mutate({
                          userId: user.userId,
                          groupId: selectedGroup,
                        })
                      }
                      disabled={removeUserMutation.isLoading}
                    >
                      <UserX className="w-4 h-4 mr-2" />
                      Remove
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>

          <Dialog open={showAddDialog} onOpenChange={setShowAddDialog}>
            <DialogContent aria-describedby="add-user-dialog-description">
              <DialogHeader>
                <DialogTitle>Add User to Group</DialogTitle>
                <DialogDescription id="add-user-dialog-description">
                  Select users to add to the current group.
                </DialogDescription>
              </DialogHeader>
              <div className="py-4">
                <Input
                  placeholder="Search users..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="mb-4"
                />
                <div className="max-h-[300px] overflow-y-auto">
                  <Table>
                    <TableHeader>
                      <TableRow>
                        <TableHead>Name</TableHead>
                        <TableHead>Email</TableHead>
                        <TableHead>Action</TableHead>
                      </TableRow>
                    </TableHeader>
                    <TableBody>
                      {availableUsers
                        .filter((user) =>
                          user.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                          user.email.toLowerCase().includes(searchTerm.toLowerCase())
                        )
                        .map((user) => (
                          <TableRow key={user.userId}>
                            <TableCell>{user.name}</TableCell>
                            <TableCell>{user.email}</TableCell>
                            <TableCell>
                              <Button
                                size="sm"
                                onClick={() => {
                                  addUserMutation.mutate({
                                    userId: user.userId,
                                    groupId: selectedGroup,
                                  });
                                }}
                                disabled={addUserMutation.isLoading}
                              >
                                Add
                              </Button>
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </div>
              </div>
              <DialogFooter>
                <Button variant="outline" onClick={() => setShowAddDialog(false)}>
                  Close
                </Button>
              </DialogFooter>
            </DialogContent>
          </Dialog>

          <Dialog open={showCreateGroupDialog} onOpenChange={setShowCreateGroupDialog}>
            <DialogContent aria-describedby="create-group-dialog-description">
              <DialogHeader>
                <DialogTitle>Create New Group</DialogTitle>
                <DialogDescription id="create-group-dialog-description">
                  Enter a name for the new account group.
                </DialogDescription>
              </DialogHeader>
              <div className="py-4">
                <Input
                  placeholder="Group name"
                  value={newGroupName}
                  onChange={(e) => setNewGroupName(e.target.value)}
                  className="mb-4"
                />
              </div>
              <DialogFooter>
                <Button
                  onClick={() => createGroupMutation.mutate(newGroupName)}
                  disabled={createGroupMutation.isLoading}
                >
                  Create
                </Button>
                <Button variant="outline" onClick={() => setShowCreateGroupDialog(false)}>
                  Cancel
                </Button>
              </DialogFooter>
            </DialogContent>
          </Dialog>
        </>
      )}
    </div>
  );
};

export default AccountManagement;
