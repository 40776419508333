import React, { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import { Layout } from 'lucide-react';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription, DialogFooter } from './ui/dialog';
import { Input } from './ui/input';
import { Button } from './ui/button';
import { Card, CardContent, CardHeader, CardTitle } from './ui/card';
import { Badge } from './ui/badge';
import { Alert, AlertDescription } from './ui/alert';
import { templateApi } from '../services/api';
import { cn } from '../lib/utils';

const TemplateSelection = ({ 
  open, 
  onOpenChange, 
  boardType, 
  accountId,
  onBoardCreated 
}) => {
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [boardName, setBoardName] = useState('');
  const [isCreating, setIsCreating] = useState(false);
  const [error, setError] = useState(null);

  // Reset state when dialog opens
  useEffect(() => {
    if (open) {
      setSelectedTemplate(null);
      setBoardName('');
      setError(null);
    }
  }, [open]);

  // Fetch templates filtered by type
  const { data: templates, isLoading } = useQuery(
    ['templates', boardType],
    async () => {
      try {
        const result = await templateApi.getAllTemplates({ 
          type: boardType,
          isActive: true 
        });
        return result;
      } catch (err) {
        console.error('Frontend: Template fetch error:', err);
        setError('Failed to load templates. Please try again.');
        throw err;
      }
    },
    {
      enabled: open && Boolean(boardType)
    }
  );

  const handleCreateBoard = async () => {
    if (!selectedTemplate || !boardName.trim()) return;
  
    setIsCreating(true);
    setError(null);

    try {
      const board = await templateApi.createBoardFromTemplate(selectedTemplate._id, {
        name: boardName.trim(),
        accountId: accountId,
        type: boardType
      });
  
      // Close dialog before navigation
      onOpenChange(false);
      
      // Navigate to new board after dialog is closed
      onBoardCreated?.(board);
    } catch (error) {
      console.error('Error creating board:', error);
      setError(error.response?.data?.message || 'Failed to create board. Please try again.');
      setIsCreating(false);
    }
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="sm:max-w-[600px] bg-white max-h-[90vh] flex flex-col">
        <DialogHeader>
          <DialogTitle>
            Create New {boardType} Board
          </DialogTitle>
          <DialogDescription>
            Select a template to create your new board from
          </DialogDescription>
        </DialogHeader>

        <div className="space-y-4 py-4 flex-1 overflow-y-auto">
          {error && (
            <Alert variant="destructive">
              <AlertDescription>{error}</AlertDescription>
            </Alert>
          )}

          <Input
            placeholder="Board name"
            value={boardName}
            onChange={(e) => setBoardName(e.target.value)}
            className="mb-4"
          />

          {isLoading ? (
            <div className="text-center py-8 text-gray-500">
              Loading templates...
            </div>
          ) : templates?.length === 0 ? (
            <div className="text-center py-8">
              <Layout className="w-12 h-12 mx-auto text-gray-400 mb-4" />
              <p className="text-gray-500">
                No templates available for {boardType} boards
              </p>
            </div>
          ) : (
            <div className="grid grid-cols-2 gap-4">
              {templates?.map((template) => (
                <Card 
                  key={template._id}
                  className={cn(
                    'cursor-pointer transition-all',
                    selectedTemplate?._id === template._id 
                      ? 'ring-2 ring-[#1f513f]' 
                      : 'hover:bg-gray-50'
                  )}
                  onClick={() => setSelectedTemplate(template)}
                >
                  <CardHeader>
                    <CardTitle className="flex items-center justify-between">
                      <span>{template.name}</span>
                      <Layout className="w-4 h-4 text-gray-500" />
                    </CardTitle>
                    <p className="text-sm text-gray-500">
                      {template.description || 'No description provided'}
                    </p>
                  </CardHeader>
                  <CardContent>
                    <div className="flex items-center space-x-2">
                      <Badge variant="secondary">
                        {template.columns?.length || 0} columns
                      </Badge>
                      <Badge variant="secondary">
                        {template.defaultTasks?.length || 0} default tasks
                      </Badge>
                    </div>
                  </CardContent>
                </Card>
              ))}
            </div>
          )}
        </div>

        <DialogFooter className="mt-6">
          <Button 
            variant="outline" 
            onClick={() => onOpenChange(false)}
            disabled={isCreating}
          >
            Cancel
          </Button>
          <Button 
            onClick={handleCreateBoard}
            disabled={!selectedTemplate || !boardName.trim() || isCreating}
          >
            {isCreating ? 'Creating...' : 'Create Board'}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default TemplateSelection;