import React from 'react';
import { useParams, useLocation, Link, useSearchParams } from 'react-router-dom';
import { useQuery, useQueryClient } from 'react-query';
import { boardApi } from '../services/api';
import { Button } from '../components/ui/button';
import BoardCard from '../components/Board/BoardCard';
import BoardDetail from '../components/Board/BoardDetail';
import { Plus, ChevronRight } from 'lucide-react';
import { useToast } from '../components/ui/use-toast';

const BoardPage = () => {
  const { accountId } = useParams();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const queryClient = useQueryClient(); 
  const { toast } = useToast();

  // Get the board ID from URL query parameters
  const activeBoardId = searchParams.get('board');

  const pathParts = location.pathname.split('/');
  const boardType = pathParts[pathParts.indexOf('board') + 1] || 
                   (pathParts.includes('workload') ? 'workload' : 'all');

  const getBoardType = (urlType) => {
    const typeMap = {
      'all': 'All',
      'business': 'Business',
      'enablement': 'Enablement',
      'workload': 'Workload'
    };
    return typeMap[urlType] || urlType;
  };

  // Query to fetch all boards
  const { data: boards, isLoading, error } = useQuery(
    ['boards', accountId, boardType],
    async () => {
      const params = { 
        accountId, 
        ...(boardType !== 'all' && { type: getBoardType(boardType) })
      };
      return await boardApi.getAllBoards(params);
    },
    {
      enabled: !!accountId
    }
  );

  // Query for active board if we have an ID
  const { data: activeBoard } = useQuery(
    ['board', activeBoardId],
    () => boardApi.getBoardById(activeBoardId),
    {
      enabled: !!activeBoardId
    }
  );

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  // Show board detail view if a board is selected
  if (activeBoardId && activeBoard) {
    return (
      <BoardDetail 
        board={activeBoard} 
        onBack={() => {
          // Update URL to remove board ID
          const newUrl = location.pathname;
          window.history.pushState({}, '', newUrl);
          window.location.reload();
        }} 
      />
    );
  }

  // Show board list view
  return (
    <div className="min-h-screen bg-background">
      <div className="mx-auto px-4 py-6">
        {/* Breadcrumb Navigation */}
        <nav className="mb-6 flex items-center text-sm text-[rgba(66,59,47,0.75)]">
          <Link 
            to={`/accounts/${accountId}`}
            className="text-[#1f513f] hover:text-[#2a6f56] transition-colors"
          >
            Account Dashboard
          </Link>
          <ChevronRight className="w-4 h-4 mx-2" />
          <span className="font-medium text-[#20201d]">
            {getBoardType(boardType)}
          </span>
        </nav>

        {/* Header content */}
        <div className="flex justify-between items-center mb-8">
          <div>
            <h1 className="text-2xl font-semibold text-text">
              {getBoardType(boardType)}
            </h1>
            <p className="text-text-muted mt-1">
              {boardType === 'workload' 
                ? 'Manage and track workload progress across your organization' 
                : 'Organize and track tasks across your organization'}
            </p>
          </div>
          <Button className="bg-[#1f513f] hover:bg-[#2a6f56] text-white">
            <Plus className="h-4 w-4 mr-2" />
            New {getBoardType(boardType)}
          </Button>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {boards?.map(board => (
            <BoardCard
              key={board._id}
              board={board}
              onClick={() => {
                // Update URL with board ID when clicking a board
                const newUrl = `${location.pathname}?board=${board._id}`;
                window.history.pushState({}, '', newUrl);
                window.location.reload(); // Force reload to update the view
              }}
              onDelete={async (boardId) => {
                try {
                  await boardApi.deleteBoard(boardId);
                  queryClient.invalidateQueries(['boards', accountId, boardType]);
                  toast({
                    title: "Board deleted",
                    description: "The board has been successfully deleted."
                  });
                } catch (error) {
                  console.error('Error deleting board:', error);
                  toast({
                    title: "Error",
                    description: "Failed to delete board. Please try again.",
                    variant: "destructive"
                  });
                }
              }}
              className="bg-white border border-border"
            />
          ))}
        </div>

        {(!boards || boards.length === 0) && (
          <div className="text-center py-10">
            <p className="text-text-muted">
              No {getBoardType(boardType).toLowerCase()}s found.
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default BoardPage;