import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useQuery } from 'react-query';
import { accountApi } from '../services/api';

const TitleManager = () => {
  const location = useLocation();
  const accountIdMatch = location.pathname.match(/\/accounts\/([^/]+)/);
  const accountId = accountIdMatch ? accountIdMatch[1] : null;

  const { data: account } = useQuery(
    ['account', accountId],
    () => accountApi.getAccountById(accountId),
    {
      enabled: !!accountId,
      staleTime: 30000 // Cache for 30 seconds
    }
  );

  useEffect(() => {
    const baseTitle = 'Belay Consumption CRM';
    
    // Update title based on account context
    if (account?.name) {
      document.title = `${account.name} - ${baseTitle}`;
    } else {
      document.title = baseTitle;
    }

    // Cleanup - reset to base title when component unmounts
    return () => {
      document.title = baseTitle;
    };
  }, [account?.name]);

  // This is a utility component that doesn't render anything
  return null;
};

export default TitleManager;