import React, { useState, useEffect } from 'react';
import { TrendingUp, TrendingDown, Shield, Plus, Trash2, ChevronDown, ChevronUp } from 'lucide-react';
import { Button } from '../ui/button';
import { Textarea } from '../ui/textarea';
import { Input } from '../ui/input';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../ui/select';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogFooter } from '../ui/dialog';
import { Card, CardContent } from '../ui/card';
import { metricsApi } from '../../services/metricsApi';
import { useToast } from '../ui/use-toast';

// Consolidated configuration for all metric types
const MetricConfig = {
    revenue: {
      title: 'Revenue Growth Opportunity',
      icon: TrendingUp,
      color: 'text-emerald-600',
      borderColor: 'border-emerald-200',
      bgColor: 'bg-emerald-50',
      description: 'Track revenue growth opportunities',
      fields: {
        driver: {
          key: 'revenueDriver',
          label: 'Primary Revenue Driver',
          type: 'select',
          options: [
            { value: 'new_market', label: 'New Market Entry' },
            { value: 'acquisition', label: 'Customer Acquisition' },
            { value: 'cross_sell', label: 'Cross-sell/Upsell' },
            { value: 'product_launch', label: 'Product Launch' },
            { value: 'retention', label: 'Customer Retention' }
          ],
          defaultValue: 'new_market'
        },
        impact: {
          key: 'revenueImpact',
          label: 'Est. Annual Revenue Impact',
          type: 'select',
          options: [
            { value: '100k', label: '$0-100k' },
            { value: '500k', label: '$100k-500k' },
            { value: '1m', label: '$500k-1M' },
            { value: '5m', label: '$1M-5M' },
            { value: '5m_plus', label: '$5M+' }
          ],
          defaultValue: '100k'
        },
        context: {
          key: 'revenueContext',
          label: 'Revenue Growth Context',
          type: 'textarea',
          placeholder: 'Describe the revenue growth opportunity and key assumptions...'
        }
      }
    },
    cost: {
      title: 'Cost Reduction Opportunity',
      icon: TrendingDown,
      color: 'text-blue-600',
      borderColor: 'border-blue-200',
      bgColor: 'bg-blue-50',
      description: 'Track cost savings opportunities',
      fields: {
        driver: {
          key: 'costDriver',
          label: 'Primary Cost Driver',
          type: 'select',
          options: [
            { value: 'automation', label: 'Manual Process Automation' },
            { value: 'resource_opt', label: 'Resource Optimization' },
            { value: 'infrastructure', label: 'Infrastructure Savings' },
            { value: 'vendor_consolidation', label: 'Vendor Consolidation' },
            { value: 'operational', label: 'Operational Efficiency' }
          ],
          defaultValue: 'automation'
        },
        impact: {
          key: 'costSavings',
          label: 'Est. Annual Cost Savings',
          type: 'select',
          options: [
            { value: '50k', label: '$0-50k' },
            { value: '200k', label: '$50k-200k' },
            { value: '500k', label: '$200k-500k' },
            { value: '1m', label: '$500k-1M' },
            { value: '1m_plus', label: '$1M+' }
          ],
          defaultValue: '50k'
        },
        context: {
          key: 'costContext',
          label: 'Cost Savings Context',
          type: 'textarea',
          placeholder: 'Describe the cost saving opportunity and implementation approach...'
        }
      }
    },
    risk: {
      title: 'Risk Mitigation Impact',
      icon: Shield,
      color: 'text-orange-600',
      borderColor: 'border-orange-200',
      bgColor: 'bg-orange-50',
      description: 'Track risk mitigation opportunities',
      fields: {
        driver: {
          key: 'riskArea',
          label: 'Primary Risk Area',
          type: 'select',
          options: [
            { value: 'security', label: 'Security/Compliance' },
            { value: 'continuity', label: 'Business Continuity' },
            { value: 'tech_debt', label: 'Technical Debt' },
            { value: 'competition', label: 'Market Competition' },
            { value: 'churn', label: 'Customer Churn' }
          ],
          defaultValue: 'security'
        },
        impact: {
          key: 'riskSeverity',
          label: 'Risk Severity',
          type: 'select',
          options: [
            { value: 'critical', label: 'Critical' },
            { value: 'high', label: 'High' },
            { value: 'medium', label: 'Medium' },
            { value: 'low', label: 'Low' }
          ],
          defaultValue: 'medium'
        },
        context: {
          key: 'riskContext',
          label: 'Risk Context',
          type: 'textarea',
          placeholder: 'Describe the risk impact and mitigation strategy...'
        }
      }
    }
  };

// Generic field renderer
const Field = ({ field, value, onChange, readOnly = false }) => {
  switch (field.type) {
    case 'select':
      return readOnly ? (
        <div>
          <div className="text-sm font-medium text-gray-500">{field.label}</div>
          <div className="mt-1 text-gray-900">
            {field.options.find(opt => opt.value === value)?.label || value}
          </div>
        </div>
      ) : (
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            {field.label}
          </label>
          <Select value={value} onValueChange={onChange}>
            <SelectTrigger>
              <SelectValue placeholder={`Select ${field.label.toLowerCase()}`} />
            </SelectTrigger>
            <SelectContent>
              {field.options.map(option => (
                <SelectItem key={option.value} value={option.value}>
                  {option.label}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
      );
    
    case 'textarea':
      return readOnly ? (
        value && (
          <div>
            <div className="text-sm font-medium text-gray-500">{field.label}</div>
            <div className="mt-1 text-gray-700 whitespace-pre-wrap">{value}</div>
          </div>
        )
      ) : (
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            {field.label}
          </label>
          <Textarea
            value={value || ''}
            onChange={(e) => onChange(e.target.value)}
            placeholder={field.placeholder}
            className="min-h-[100px]"
          />
        </div>
      );
  
    default:
      return readOnly ? (
        value && (
          <div>
            <div className="text-sm font-medium text-gray-500">{field.label}</div>
            <div className="mt-1 text-gray-900">{value}</div>
          </div>
        )
      ) : (
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            {field.label}
          </label>
          <Input
            type="text"
            value={value || ''}
            onChange={(e) => onChange(e.target.value)}
            placeholder={field.placeholder}
          />
        </div>
      );
  }
};

// Type Selection Dialog
const TypeSelectionDialog = ({ open, onOpenChange, onSelectType, activeMetrics = [] }) => {
  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Add Business Value Metric</DialogTitle>
        </DialogHeader>
        <div className="grid grid-cols-1 gap-4 py-4">
          {Object.entries(MetricConfig).map(([type, config]) => (
            <Button
              key={type}
              variant="outline"
              className="flex items-center justify-start gap-3 p-4 h-auto"
              onClick={() => onSelectType(type)}
            >
              <config.icon className={`w-5 h-5 ${config.color}`} />
              <div className="text-left">
                <div className="font-medium">{config.title}</div>
                <div className="text-sm text-gray-500">{config.description}</div>
              </div>
            </Button>
          ))}
        </div>
      </DialogContent>
    </Dialog>
  );
};

// Edit Metric Dialog
const EditMetricDialog = ({ open, onOpenChange, metric, onSave }) => {
  const [formData, setFormData] = useState(metric || {});
  const config = metric ? MetricConfig[metric.type] : null;

  if (!config) return null;

  const handleFieldChange = (key, value) => {
    setFormData(prev => ({ ...prev, [key]: value }));
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="sm:max-w-[600px]">
        <DialogHeader>
          <DialogTitle>{config.title}</DialogTitle>
        </DialogHeader>
        <div className="py-4">
          <div className="space-y-6">
            <div className="grid grid-cols-2 gap-6">
              <Field
                field={config.fields.driver}
                value={formData[config.fields.driver.key]}
                onChange={(value) => handleFieldChange(config.fields.driver.key, value)}
              />
              <Field
                field={config.fields.impact}
                value={formData[config.fields.impact.key]}
                onChange={(value) => handleFieldChange(config.fields.impact.key, value)}
              />
            </div>
            <Field
              field={config.fields.context}
              value={formData[config.fields.context.key]}
              onChange={(value) => handleFieldChange(config.fields.context.key, value)}
            />
          </div>
        </div>
        <DialogFooter>
          <Button variant="outline" onClick={() => onOpenChange(false)}>
            Cancel
          </Button>
          <Button onClick={() => onSave(formData)}>
            Save Metric
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

// Metric Card Component
const MetricCard = ({ metric, isExpanded, onToggle, onDelete }) => {
  const config = MetricConfig[metric.type];
  
  return (
    <Card className={`border ${config.borderColor}`}>
      <CardContent className="p-4">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-3 flex-1">
            <config.icon className={`w-5 h-5 ${config.color}`} />
            <div className="flex-1">
              <h4 className="font-medium text-gray-900">{config.title}</h4>
              <div className="text-sm text-gray-600">
                {config.fields.driver.options.find(opt => opt.value === metric[config.fields.driver.key])?.label} • 
                {config.fields.impact.options.find(opt => opt.value === metric[config.fields.impact.key])?.label}
              </div>
            </div>
          </div>
          <div className="flex items-center gap-2">
            <Button
              variant="ghost"
              size="sm"
              onClick={() => onDelete(metric)}
            >
              <Trash2 className="w-4 h-4 text-gray-400 hover:text-red-500" />
            </Button>
            <Button
              variant="ghost"
              size="sm"
              onClick={() => onToggle(metric._id)}
            >
              {isExpanded ? 
                <ChevronUp className="w-4 h-4" /> : 
                <ChevronDown className="w-4 h-4" />
              }
            </Button>
          </div>
        </div>

        {isExpanded && (
          <div className="mt-4 pt-4 border-t border-gray-100 space-y-4">
            <div className="grid grid-cols-2 gap-6">
              <Field
                field={config.fields.driver}
                value={metric[config.fields.driver.key]}
                readOnly
              />
              <Field
                field={config.fields.impact}
                value={metric[config.fields.impact.key]}
                readOnly
              />
            </div>
            <Field
              field={config.fields.context}
              value={metric[config.fields.context.key]}
              readOnly
            />
          </div>
        )}
      </CardContent>
    </Card>
  );
};

// Main Component
const MetricsSection = ({ boardId, businessCaseId, initialMetrics = [] }) => {
  const { toast } = useToast();
  const [metrics, setMetrics] = useState(initialMetrics);
  const [showTypeDialog, setShowTypeDialog] = useState(false);
  const [showEditDialog, setShowEditDialog] = useState(false);
  const [editingMetric, setEditingMetric] = useState(null);
  const [expandedMetricId, setExpandedMetricId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const loadMetrics = async () => {
      if (!businessCaseId) return;
      
      try {
        const loadedMetrics = await metricsApi.getMetrics(businessCaseId);
        setMetrics(loadedMetrics || []);
      } catch (error) {
        toast({
          title: 'Error loading metrics',
          description: error.message,
          variant: 'destructive'
        });
      }
    };

    loadMetrics();
  }, [businessCaseId, toast]);

  const handleTypeSelect = (type) => {
    const newMetric = {
      type: type,
      businessCaseId,
      boardId,
      [MetricConfig[type].fields.driver.key]: MetricConfig[type].fields.driver.defaultValue,
      [MetricConfig[type].fields.impact.key]: MetricConfig[type].fields.impact.defaultValue,
      [MetricConfig[type].fields.context.key]: ''
    };
  
    setEditingMetric(newMetric);
    setShowTypeDialog(false);
    setShowEditDialog(true);
  };
  
  const handleSaveMetric = async (metricData) => {
    setIsLoading(true);
    try {
      const completeMetricData = {
        ...metricData,
        type: editingMetric.type,
        boardId,
        businessCaseId
      };
  
      const savedMetric = await metricsApi.createMetric(businessCaseId, completeMetricData);
      setMetrics(prev => [...prev, savedMetric]);
      setShowEditDialog(false);
      setEditingMetric(null);
      toast({
        title: 'Metric added',
        description: 'New metric has been added successfully'
      });
    } catch (error) {
      console.error('Error saving metric:', error);
      toast({
        title: 'Error adding metric',
        description: error.message,
        variant: 'destructive'
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteMetric = async (metric) => {
    setIsLoading(true);
    try {
      await metricsApi.deleteMetric(metric._id);
      setMetrics(prev => prev.filter(m => m._id !== metric._id));
      setExpandedMetricId(null);
      toast({
        title: 'Metric removed',
        description: 'The metric has been removed successfully'
      });
    } catch (error) {
      toast({
        title: 'Error removing metric',
        description: error.message,
        variant: 'destructive'
      });
    } finally {
      setIsLoading(false);
    }
  };

  // Group metrics by type for better organization
  const groupedMetrics = metrics.reduce((acc, metric) => {
    const type = metric.type;
    if (!acc[type]) {
      acc[type] = [];
    }
    acc[type].push(metric);
    return acc;
  }, {});

  return (
    <div className="bg-white rounded-lg border border-gray-200 overflow-hidden">
      <div className="px-6 py-4 bg-gray-50 border-b border-gray-200">
        <h3 className="text-sm font-medium text-gray-900">Business Value Metrics</h3>
      </div>
      
      <div className="p-6 space-y-6">
        {/* Add Metric Button - No longer limited by count */}
        <Button
          variant="outline"
          onClick={() => setShowTypeDialog(true)}
          className="flex items-center gap-2 w-full justify-center"
          disabled={isLoading}
        >
          <Plus className="w-4 h-4" />
          Add Metric
        </Button>

        {/* Metrics List - Grouped by type */}
        {Object.entries(groupedMetrics).map(([type, typeMetrics]) => (
          <div key={type} className="space-y-4">
            <h4 className="font-medium text-gray-700">{MetricConfig[type].title}s</h4>
            {typeMetrics.map((metric) => (
              <MetricCard
                key={metric._id}
                metric={metric}
                isExpanded={expandedMetricId === metric._id}
                onToggle={(id) => setExpandedMetricId(expandedMetricId === id ? null : id)}
                onDelete={handleDeleteMetric}
              />
            ))}
          </div>
        ))}

        {/* Empty State */}
        {metrics.length === 0 && (
          <div className="text-center py-12">
            <Shield className="w-12 h-12 mx-auto text-gray-400 mb-4" />
            <h3 className="text-lg font-medium text-gray-900 mb-2">
              No Metrics Added
            </h3>
            <p className="text-gray-500 mb-4">
              Add business value metrics to track revenue, cost, or risk impact
            </p>
          </div>
        )}
      </div>

      {/* Dialogs */}
      <TypeSelectionDialog
        open={showTypeDialog}
        onOpenChange={setShowTypeDialog}
        onSelectType={handleTypeSelect}
      />

      <EditMetricDialog
        open={showEditDialog}
        onOpenChange={setShowEditDialog}
        metric={editingMetric}
        onSave={handleSaveMetric}
      />
    </div>
  );
};

export default MetricsSection;