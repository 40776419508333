import React, { useState, useEffect, useCallback } from 'react';
import { useDebounce } from 'use-debounce';
import { cn } from '../../../lib/utils';
import { Input } from '../../ui/input';
import { Textarea } from '../../ui/textarea';
import { Switch } from '../../ui/switch';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../../ui/select';

const DynamicField = ({ field, value, onChange, autoSave = true }) => {
  // Initialize localValue from prop value
  const [localValue, setLocalValue] = useState(value);
  const [debouncedValue] = useDebounce(localValue, 1000);
  
  // Update localValue when prop value changes
  useEffect(() => {
    setLocalValue(value);
  }, [value]);

  // Memoize the onChange handler to prevent unnecessary re-renders
  const handleDebouncedChange = useCallback(() => {
    if (autoSave && debouncedValue !== undefined && debouncedValue !== value) {
      onChange(field.key, debouncedValue);
    }
  }, [autoSave, debouncedValue, field.key, onChange, value]);

  // Handle debounced changes
  useEffect(() => {
    handleDebouncedChange();
  }, [handleDebouncedChange]);

  const handleChange = (newValue) => {
    setLocalValue(newValue);
    if (!autoSave) {
      onChange(field.key, newValue);
    }
  };

  const inputClasses = cn(
    "transition-all duration-200",
    "hover:border-primary/50 focus:border-primary",
    "bg-white dark:bg-gray-950"
  );

  switch (field.type) {
    case 'textarea':
      return (
        <Textarea
          value={localValue || ''}
          onChange={(e) => handleChange(e.target.value)}
          className={cn(inputClasses, "min-h-[100px] resize-none")}
          placeholder={`Enter ${field.label.toLowerCase()}...`}
        />
      );
    case 'number':
      return (
        <Input
          type="number"
          value={localValue || ''}
          onChange={(e) => handleChange(e.target.value)}
          className={inputClasses}
          placeholder={`Enter ${field.label.toLowerCase()}...`}
        />
      );
    case 'date':
      return (
        <Input
          type="date"
          value={localValue || ''}
          onChange={(e) => handleChange(e.target.value)}
          className={inputClasses}
        />
      );
    case 'select':
      return (
        <Select
          value={localValue || ''}
          onValueChange={handleChange}
        >
          <SelectTrigger className={inputClasses}>
            <SelectValue placeholder={`Select ${field.label.toLowerCase()}...`} />
          </SelectTrigger>
          <SelectContent>
            {field.options.map(option => (
              <SelectItem key={option.value} value={option.value}>
                {option.label}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      );
    case 'boolean':
      return (
        <Switch
          checked={localValue || false}
          onCheckedChange={handleChange}
          className="data-[state=checked]:bg-primary"
        />
      );
    default:
      return (
        <Input
          value={localValue || ''}
          onChange={(e) => handleChange(e.target.value)}
          className={inputClasses}
          placeholder={`Enter ${field.label.toLowerCase()}...`}
        />
      );
  }
};

export default DynamicField;