import React from 'react';
import { Tabs, TabsList, TabsTrigger, TabsContent } from '../ui/tabs';
import { LayoutGrid, FileText, ChevronLeft } from 'lucide-react';
import Board from './Board';
import { BusinessCaseSection } from '../BusinessCase';
import BoardMaturityBadge from './BoardMaturityBadge';

const BoardDetail = ({ board, onBack }) => {
  const [activeTab, setActiveTab] = React.useState('tasks');

  return (
    <div className="min-h-screen bg-gray-50">
      <div className="bg-white border-b border-gray-200">
        <div className="max-w-7xl mx-auto px-4 py-4">
          <button 
            onClick={onBack}
            className="flex items-center gap-2 text-sm text-gray-600 hover:text-gray-900 mb-4"
          >
            <ChevronLeft className="h-4 w-4" />
            Back to {board.type}s
          </button>
          
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-3">
              <h1 className="text-2xl font-semibold text-gray-900">
                {board.name}
              </h1>
              <BoardMaturityBadge board={board} />
            </div>
          </div>
        </div>
      </div>

      <div className="max-w-7xl mx-auto px-4 py-6">
        <Tabs value={activeTab} onValueChange={setActiveTab} className="space-y-6">
          <TabsList className="bg-white border border-gray-200 p-1">
            <TabsTrigger 
              value="tasks"
              className="data-[state=active]:bg-[#1f513f] data-[state=active]:text-white"
            >
              <LayoutGrid className="h-4 w-4 mr-2" />
              Tasks
            </TabsTrigger>
            {board.type === 'Workload' && (
              <TabsTrigger 
                value="business-case"
                className="data-[state=active]:bg-[#1f513f] data-[state=active]:text-white"
              >
                <FileText className="h-4 w-4 mr-2" />
                Business Case
              </TabsTrigger>
            )}
          </TabsList>

          <TabsContent value="tasks">
            <Board 
              boardId={board._id}
              completeness={board.type === 'Workload'}
            />
          </TabsContent>

          {board.type === 'Workload' && (
            <TabsContent value="business-case">
              <BusinessCaseSection 
                boardId={board._id}
                isWorkload={true}
              />
            </TabsContent>
          )}
        </Tabs>
      </div>
    </div>
  );
};

export default BoardDetail;