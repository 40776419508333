import React from 'react';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import { Button } from '../ui/button';
import { GripVertical, Trash2 } from 'lucide-react';
import { DynamicField } from './fields';
import { cn } from '../../lib/utils';

const CustomFields = ({ 
  businessCase, 
  showSettingsMenu,
  onFieldChange,
  onFieldDragEnd,
  onDeleteField 
}) => {
  if (!businessCase?.customFields?.length) return null;

  return (
    <div className="bg-white rounded-lg border border-gray-200 overflow-hidden">
      <div className="px-6 py-4 bg-[#1f513f] border-b border-gray-200">
        <h3 className="text-sm font-medium text-white">Additional Details</h3>
      </div>
      <DragDropContext onDragEnd={onFieldDragEnd}>
        <Droppable droppableId="custom-fields">
          {(provided) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              className="p-6 space-y-6"
            >
              {businessCase.customFields.map((field, index) => (
                <Draggable
                  key={field.key}
                  draggableId={field.key}
                  index={index}
                  isDragDisabled={!showSettingsMenu}
                >
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      className={cn(
                        "rounded-lg",
                        snapshot.isDragging && "bg-gray-50"
                      )}
                    >
                      <div className="flex items-start gap-4">
                        {showSettingsMenu && (
                          <div {...provided.dragHandleProps}>
                            <GripVertical className="w-4 h-4 text-gray-400" />
                          </div>
                        )}
                        <div className="flex-1">
                          <label className="block text-sm font-medium text-gray-700 mb-2">
                            {field.label}
                            {field.required && (
                              <span className="text-red-500 ml-1">*</span>
                            )}
                          </label>
                          <DynamicField
                            field={field}
                            value={businessCase?.customFieldValues?.[field.key]}
                            onChange={onFieldChange}
                          />
                        </div>
                        {showSettingsMenu && (
                          <Button
                            variant="ghost"
                            size="sm"
                            onClick={() => onDeleteField(field.key)}
                          >
                            <Trash2 className="w-4 h-4 text-red-500" />
                          </Button>
                        )}
                      </div>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};

export default CustomFields;