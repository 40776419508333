import React from 'react';
import { Loader2 } from 'lucide-react';

const LoadingScreen = () => {
  return (
    <div className="min-h-screen bg-[#f4f2ed] flex flex-col items-center justify-center">
      <div className="bg-white p-8 rounded-lg shadow-sm flex flex-col items-center space-y-4">
        {/* Logo */}
        <img 
          src="/logo.png"
          alt="Logo"
          className="h-12 w-auto mb-4"
        />
        
        {/* Spinner */}
        <Loader2 className="h-8 w-8 text-[#1f513f] animate-spin" />
        
        {/* Loading text */}
        <p className="text-[rgba(66,59,47,0.75)] text-sm">
          Loading your workspace...
        </p>
      </div>
    </div>
  );
};

export default LoadingScreen;