// src/pages/PrivacyPage.js
import React, { useState, useEffect } from 'react';
import { Card } from '../components/ui/card';
import ReactMarkdown from 'react-markdown';
import policy from '../content/policy.md';

const PrivacyPage = () => {
    const [content, setContent] = useState('');

    useEffect(() => {
      // Fetch the terms content
      fetch(policy)
        .then(response => response.text())
        .then(text => setContent(text))
        .catch(error => console.error('Error loading terms:', error));
    }, []);
  
    return (
      <div className="container mx-auto px-4 py-8 max-w-4xl">
        <Card className="p-8">
          <div className="prose prose-slate max-w-none">
            <ReactMarkdown>{content}</ReactMarkdown>
          </div>
        </Card>
  
        {/* Optional acceptance section - uncomment if needed */}
        {/* <div className="mt-8 flex justify-center">
          <Button onClick={() => handleAcceptTerms()}>
            I Accept the Terms and Conditions
          </Button>
        </div> */}
      </div>
    );
  };

export default PrivacyPage;