import React, { useState } from 'react';
import { Card, CardContent, CardHeader, CardTitle } from './ui/card';
import { BarChart, Bar, PieChart, Pie, Cell, XAxis, YAxis, Tooltip, ResponsiveContainer, Legend } from 'recharts';
import { TrendingUp, TrendingDown, Shield } from 'lucide-react';
import { cn } from '../lib/utils';
import MetricsDrillDown from './BusinessCase/MetricsDrillDown';

// Design system colors
const CHART_COLORS = {
  brand: [
    'hsl(210 45% 64%)',  // Sky
    'hsl(16 47% 49%)',   // Desert Rock
    'hsl(39 50% 48%)',   // Autumn Leaf
    'hsl(210 60% 34%)',  // Alpine Lake
    'hsl(16 47% 69%)',   // Canyon
    'hsl(39 65% 73%)'    // Morning Sun
  ],
  risk: {
    critical: '#ef4444',
    high: '#f97316',
    medium: '#eab308',
    low: '#22c55e'
  }
};

const ValueCard = ({ title, value, description, icon: Icon, onClick }) => {
  return (
    <Card 
      className={cn(
        "bg-white/95 backdrop-blur border-none shadow-lg transition-all",
        onClick && "hover:shadow-xl cursor-pointer"
      )}
      onClick={onClick}
    >
      <CardContent className="pt-6">
        <div className="flex items-center justify-between mb-4">
          <div className="p-2 rounded-full bg-brand-primary/10">
            <Icon className="w-5 h-5 text-brand-primary" />
          </div>
          {description && (
            <div className="text-sm text-muted-foreground">{description}</div>
          )}
        </div>
        <div>
          <div className="text-2xl font-bold text-foreground">{value}</div>
          <div className="text-sm text-muted-foreground">{title}</div>
        </div>
      </CardContent>
    </Card>
  );
};

const ChartCard = ({ title, children, onClick }) => {
  return (
    <Card 
      className={cn(
        "p-4 transition-all",
        onClick && "hover:shadow-lg cursor-pointer"
      )}
      onClick={onClick}
    >
      <CardHeader>
        <CardTitle>{title}</CardTitle>
      </CardHeader>
      <CardContent>
        <div className="h-64">
          {children}
        </div>
      </CardContent>
    </Card>
  );
};

const MetricsDashboard = ({ workloadMetrics }) => {
  const [showDrillDown, setShowDrillDown] = useState(false);
  const [selectedTab, setSelectedTab] = useState('revenue');

  if (!workloadMetrics?.length) {
    return null;
  }

  // Calculate metrics (same calculations as before)
  const valueMetrics = {
    revenue: {
      total: workloadMetrics.reduce((sum, metric) => {
        const valueMap = {
          '100k': 100000,
          '500k': 500000,
          '1m': 1000000,
          '5m': 5000000,
          '5m_plus': 7500000
        };
        return sum + (metric.type === 'revenue' && metric.revenueImpact ? valueMap[metric.revenueImpact] || 0 : 0);
      }, 0),
      opportunities: workloadMetrics.filter(m => m.type === 'revenue').length
    },
    cost: {
      total: workloadMetrics.reduce((sum, metric) => {
        const valueMap = {
          '50k': 50000,
          '200k': 200000,
          '500k': 500000,
          '1m': 1000000,
          '1m_plus': 1500000
        };
        return sum + (metric.type === 'cost' && metric.costSavings ? valueMap[metric.costSavings] || 0 : 0);
      }, 0),
      opportunities: workloadMetrics.filter(m => m.type === 'cost').length
    },
    risk: {
      critical: workloadMetrics.filter(m => m.type === 'risk' && m.riskSeverity === 'critical').length,
      high: workloadMetrics.filter(m => m.type === 'risk' && m.riskSeverity === 'high').length,
      total: workloadMetrics.filter(m => m.type === 'risk').length
    }
  };

  // Distribution data calculations (same as before)
  const revenueDistribution = Object.entries(
    workloadMetrics
      .filter(m => m.type === 'revenue' && m.revenueDriver)
      .reduce((acc, metric) => {
        const key = metric.revenueDriver;
        acc[key] = (acc[key] || 0) + 1;
        return acc;
      }, {})
  ).map(([key, value]) => ({
    name: key.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' '),
    value
  }));

  const costDistribution = Object.entries(
    workloadMetrics
      .filter(m => m.type === 'cost' && m.costDriver)
      .reduce((acc, metric) => {
        const key = metric.costDriver;
        acc[key] = (acc[key] || 0) + 1;
        return acc;
      }, {})
  ).map(([key, value]) => ({
    name: key.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' '),
    value
  }));

  const riskAreaData = workloadMetrics
    .filter(m => m.type === 'risk' && m.riskArea && m.riskSeverity)
    .reduce((acc, metric) => {
      const area = metric.riskArea.split('_')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
      
      const existingArea = acc.find(item => item.name === area);
      if (existingArea) {
        existingArea[metric.riskSeverity] = (existingArea[metric.riskSeverity] || 0) + 1;
      } else {
        acc.push({
          name: area,
          critical: metric.riskSeverity === 'critical' ? 1 : 0,
          high: metric.riskSeverity === 'high' ? 1 : 0,
          medium: metric.riskSeverity === 'medium' ? 1 : 0,
          low: metric.riskSeverity === 'low' ? 1 : 0
        });
      }
      return acc;
    }, []);

  const handleCardClick = (tab) => {
    setSelectedTab(tab);
    setShowDrillDown(true);
  };

  return (
    <div className="space-y-6">
      {/* Summary Cards */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        <ValueCard
          title="Revenue Growth Potential"
          value={`$${(valueMetrics.revenue.total / 1000000).toFixed(1)}M`}
          description={`${valueMetrics.revenue.opportunities} opportunities`}
          icon={TrendingUp}
          onClick={() => handleCardClick('revenue')}
        />
        <ValueCard
          title="Cost Reduction Potential"
          value={`$${(valueMetrics.cost.total / 1000000).toFixed(1)}M`}
          description={`${valueMetrics.cost.opportunities} opportunities`}
          icon={TrendingDown}
          onClick={() => handleCardClick('cost')}
        />
        <ValueCard
          title="Risk Profile"
          value={valueMetrics.risk.total}
          description={`${valueMetrics.risk.critical} Critical, ${valueMetrics.risk.high} High Impact`}
          icon={Shield}
          onClick={() => handleCardClick('risk')}
        />
      </div>

      {/* Charts */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {revenueDistribution.length > 0 && (
          <ChartCard 
            title="Revenue Growth Drivers"
            onClick={() => handleCardClick('revenue')}
          >
            <ResponsiveContainer width="100%" height="100%">
              <PieChart>
                <Pie
                  data={revenueDistribution}
                  dataKey="value"
                  nameKey="name"
                  cx="50%"
                  cy="50%"
                  outerRadius={80}
                  label
                >
                  {revenueDistribution.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={CHART_COLORS.brand[index % CHART_COLORS.brand.length]} />
                  ))}
                </Pie>
                <Tooltip />
                <Legend />
              </PieChart>
            </ResponsiveContainer>
          </ChartCard>
        )}

        {costDistribution.length > 0 && (
          <ChartCard 
            title="Cost Reduction Drivers"
            onClick={() => handleCardClick('cost')}
          >
            <ResponsiveContainer width="100%" height="100%">
              <PieChart>
                <Pie
                  data={costDistribution}
                  dataKey="value"
                  nameKey="name"
                  cx="50%"
                  cy="50%"
                  outerRadius={80}
                  label
                >
                  {costDistribution.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={CHART_COLORS.brand[index % CHART_COLORS.brand.length]} />
                  ))}
                </Pie>
                <Tooltip />
                <Legend />
              </PieChart>
            </ResponsiveContainer>
          </ChartCard>
        )}
      </div>

      {riskAreaData.length > 0 && (
        <ChartCard 
          title="Risk Profile by Area"
          onClick={() => handleCardClick('risk')}
        >
          <ResponsiveContainer width="100%" height="100%">
            <BarChart data={riskAreaData}>
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="critical" stackId="a" fill={CHART_COLORS.risk.critical} />
              <Bar dataKey="high" stackId="a" fill={CHART_COLORS.risk.high} />
              <Bar dataKey="medium" stackId="a" fill={CHART_COLORS.risk.medium} />
              <Bar dataKey="low" stackId="a" fill={CHART_COLORS.risk.low} />
            </BarChart>
          </ResponsiveContainer>
        </ChartCard>
      )}

      {/* Drill Down Dialog */}
      <MetricsDrillDown
        isOpen={showDrillDown}
        onClose={() => setShowDrillDown(false)}
        metrics={workloadMetrics}
        initialTab={selectedTab}
      />
    </div>
  );
};

export default MetricsDashboard;