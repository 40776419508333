import React, { useState, useEffect } from 'react';
import { Routes, Route, useParams, useNavigate } from 'react-router-dom';
import { useQuery, useQueryClient, useMutation } from 'react-query';
import { accountApi, boardApi, CognitoApi } from '../services/api';
import { Card, CardContent } from '../components/ui/card';
import { Button } from '../components/ui/button';
import { Plus, Users, Briefcase, Lightbulb, Layout, UserPlus } from 'lucide-react';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription, DialogFooter } from '../components/ui/dialog';
import { Input } from '../components/ui/input';
import { TrendingUp, Shield } from 'lucide-react';
import { metricsApi } from '../services/metricsApi';
import TemplateSelection from '../components/TemplateSelection';
import MetricsDashboard from '../components/MetricsDashboard';
import MetricsDrillDown from '../components/BusinessCase/MetricsDrillDown';
import { useToast } from '../components/ui/use-toast';
import BoardPage from './BoardPage';
import BoardCard from '../components/Board/BoardCard'
import { cn } from '../lib/utils';
import { Checkbox } from '../components/ui/checkbox';
import {
  Table,
  TableHeader,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
} from '../components/ui/table';

// Color tokens
const colors = {
  brand: {
    primary: '#1f513f',
    secondary: '#406eb5',
  },
  text: {
    primary: 'rgba(12, 11, 8, 0.75)',
    secondary: 'rgba(66, 59, 47, 0.75)',
    emphasis: '#20201d',
  },
  background: {
    primary: '#ffffff',
    secondary: '#f4f2ed',
    tertiary: '#f9f8f6',
  },
  border: {
    primary: '#dcd6cb',
    secondary: '#928b80',
  }
};

// eslint-disable-next-line no-unused-vars
const StatsCard = ({ title, value }) => (
  <Card className={cn(
    `bg-[${colors.background.tertiary}]`,
    `hover:bg-[${colors.background.secondary}]`,
    'transition-colors'
  )}>
    <CardContent className="pt-6">
      <div className={`text-2xl font-bold text-[${colors.brand.primary}]`}>
        {value}
      </div>
      <div className={`text-sm text-[${colors.text.secondary}]`}>
        {title}
      </div>
    </CardContent>
  </Card>
);

// Empty Board Card Component
const EmptyBoardCard = ({ type, Icon, onCreateBoard }) => (
  <Card className={cn(
    'border-dashed',
    `border-[${colors.border.primary}]`
  )}>
    <CardContent className="flex flex-col items-center justify-center py-8">
      <Icon className={cn(
        'w-8 h-8 mb-2',
        `text-[${colors.text.secondary}]`
      )} />
      <p className={`text-[${colors.text.secondary}] text-center`}>
        No {type} boards yet
      </p>
      <Button 
        variant="ghost" 
        size="sm" 
        className={cn(
          'mt-2',
          `text-[${colors.brand.primary}]`,
          `hover:bg-[${colors.background.secondary}]`
        )}
        onClick={onCreateBoard}
      >
        Create your first {type} board
      </Button>
    </CardContent>
  </Card>
);

// Add User Dialog Component
const AddUserDialog = ({ open, onOpenChange, accountId }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [includeSubAccounts, setIncludeSubAccounts] = useState(false);
  const { toast } = useToast();
  const queryClient = useQueryClient();
  const [searchDomain, setSearchDomain] = useState('');

  // Get group name from accountId
  const groupName = `Org_${accountId}`;

  // Extract domain from email
  const getDomain = (email) => {
    return email.split('@')[1];
  };

  // Fetch available users only when searching
  const { data: availableUsers = [], isFetching } = useQuery(
    ['available-users', groupName, searchTerm],
    async () => {
      if (!groupName || !searchTerm || searchTerm.length < 3) return [];
      const data = await CognitoApi.listAvailableUsers(groupName);
      
      // Filter users by domain if we have a domain context
      if (searchDomain) {
        return data.filter(user => getDomain(user.email) === searchDomain);
      }
      
      return data;
    },
    {
      enabled: open && searchTerm.length >= 3,
      keepPreviousData: true
    }
  );

  // Get account to determine domain context
useQuery(
    ['account', accountId],
    () => accountApi.getAccountById(accountId),
    {
      enabled: open,
      onSuccess: (data) => {
        // If account has a primary domain setting, use it
        if (data.primaryDomain) {
          setSearchDomain(data.primaryDomain);
        }
      }
    }
  );

  // Add user mutation
  const addUserMutation = useMutation(
    async ({ userId, includeSubAccounts }) => {
      try {
        // First add to main account
        const response = await CognitoApi.addUsertoGroup(groupName, userId);
        
        if (includeSubAccounts) {
          // Get sub-accounts
          const subAccounts = await accountApi.getSubAccounts(accountId);
          
          // Add user to each sub-account group
          if (subAccounts && subAccounts.length > 0) {
            await Promise.all(
              subAccounts.map(subAccount => 
                CognitoApi.addUsertoGroup(`Org_${subAccount._id}`, userId)
              )
            );
          }
        }
        
        return response;
      } catch (error) {
        console.error('Error in addUserMutation:', error);
        throw error;
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['groupUsers', groupName]);
        toast({
          title: 'User added successfully',
          description: includeSubAccounts 
            ? 'The user has been added to the account and all sub-accounts'
            : 'The user has been added to the account',
        });
        onOpenChange(false);
      },
      onError: (error) => {
        toast({
          title: 'Error adding user',
          description: error.message || 'Failed to add user',
          variant: 'destructive',
        });
      },
    }
  );

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="sm:max-w-[600px]">
        <DialogHeader>
          <DialogTitle>Add User to Account</DialogTitle>
          <DialogDescription>
            Search for users to add to this account. Enter at least 3 characters to search.
            {searchDomain && (
              <p className="mt-1 text-sm text-muted-foreground">
                Showing users from @{searchDomain}
              </p>
            )}
          </DialogDescription>
        </DialogHeader>
        
        <div className="py-4">
          <Input
            placeholder="Search by name or email..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="mb-4"
          />

          <div className="mb-4">
            <div className="flex items-center space-x-2">
              <Checkbox
                id="subaccounts"
                checked={includeSubAccounts}
                onCheckedChange={setIncludeSubAccounts}
              />
              <label
                htmlFor="subaccounts"
                className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
              >
                Also add to all sub-accounts
              </label>
            </div>
          </div>

          <div className="max-h-[300px] overflow-y-auto">
            {searchTerm.length < 3 ? (
              <p className="text-center text-sm text-muted-foreground py-4">
                Enter at least 3 characters to search for users
              </p>
            ) : isFetching ? (
              <p className="text-center text-sm text-muted-foreground py-4">
                Searching...
              </p>
            ) : availableUsers.length === 0 ? (
              <p className="text-center text-sm text-muted-foreground py-4">
                No users found
              </p>
            ) : (
              <Table>
                <TableHeader>
                  <TableRow>
                    <TableHead>Name</TableHead>
                    <TableHead>Email</TableHead>
                    <TableHead>Action</TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {availableUsers.map((user) => (
                    <TableRow key={user.userId}>
                      <TableCell>{user.name}</TableCell>
                      <TableCell>{user.email}</TableCell>
                      <TableCell>
                        <Button
                          size="sm"
                          onClick={() => {
                            addUserMutation.mutate({
                              userId: user.userId,
                              includeSubAccounts
                            });
                          }}
                          disabled={addUserMutation.isLoading}
                        >
                          Add
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            )}
          </div>
        </div>

        <DialogFooter>
          <Button variant="outline" onClick={() => onOpenChange(false)}>
            Close
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

// Separate Dashboard Component
const AccountDashboard = ({ workloadMetrics, metricsLoading }) => {
  const { accountId } = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [showTemplateDialog, setShowTemplateDialog] = useState(false);
  const [selectedBoardType, setSelectedBoardType] = useState(null);
  const { toast } = useToast();
  const [setShowCreateAccountDialog] = useState(false);
  const [showAddUserDialog, setShowAddUserDialog] = useState(false);

  // Account query
  const { 
    // eslint-disable-next-line no-unused-vars
    data: account, 
    // eslint-disable-next-line no-unused-vars
    isLoading: accountLoading, 
    // eslint-disable-next-line no-unused-vars
    error: accountError 
  } = useQuery(
    ['account', accountId],
    () => accountApi.getAccountById(accountId),
    {
      enabled: Boolean(accountId),
      retry: 1,
      onError: (error) => {
        console.error('Account fetch error:', error);
        toast({
          title: "Error",
          description: "Failed to load account details",
          variant: "destructive"
        });
        if (error?.response?.status === 400 || error?.response?.status === 404) {
          navigate('/');
        }
      }
    }
  );

  // Boards query
  const { 
    data: boards, 
    // eslint-disable-next-line no-unused-vars
    isLoading: boardsLoading,
    // eslint-disable-next-line no-unused-vars
    error: boardsError 
  } = useQuery(
    ['boards', accountId],
    () => boardApi.getAllBoards({ accountId }),
    {
      enabled: Boolean(accountId)
    }
  );
  
  // Add debug logs for the metrics section render
  console.log('Current state:', {
    metricsLoading,
    workloadMetricsLength: workloadMetrics?.length,
    accountId,
    boards: boards?.length
  });
  const boardTypes = [
    { 
      type: 'Workload', 
      icon: Users, 
      description: 'Track team member assignments and capacity',
      path: 'workload'
    },
    { 
      type: 'Business', 
      icon: Briefcase, 
      description: 'Manage business initiatives and goals',
      path: 'business'
    },
    { 
      type: 'Enablement', 
      icon: Lightbulb, 
      description: 'Organize enablement and training activities',
      path: 'enablement'
    }
  ];

  // Group boards by type
  const boardsByType = React.useMemo(() => {
    if (!boards) return {};
    
    return boards.reduce((acc, board) => {
      const type = board.type || 'Other';
      if (!acc[type]) {
        acc[type] = [];
      }
      acc[type].push(board);
      return acc;
    }, {});
  }, [boards]);

  const handleCreateBoard = (type) => {
    setSelectedBoardType(type);
    setShowTemplateDialog(true);
  };

  const handleBoardCreated = (board) => {
    queryClient.invalidateQueries(['boards', accountId]);

    // Calculate the correct navigation path
    const pathType = board.type.toLowerCase();
    
    const navigationPath = pathType === 'workload' 
      ? `/accounts/${accountId}/workload/${board._id}`
      : `/accounts/${accountId}/board/${pathType}`;
    
    navigate(navigationPath);
  };

  const handleBoardClick = (board) => {
    const pathType = board.type.toLowerCase();
    const baseUrl = `/accounts/${accountId}/`;
    
    const navigationPath = pathType === 'workload'
      ? `${baseUrl}workload/default`
      : `${baseUrl}board/${pathType}`;
  
    // Add the board ID as a query parameter
    navigate(`${navigationPath}?board=${board._id}`);
  };
  

  return (
    <div className="space-y-8">
      {/* Enhanced Account Header with brand color background */}
      <div className={cn(
        `bg-[${colors.brand.primary}]`,
        'px-6 py-8 -mx-6 -mt-6'
      )}>
        <div className="max-w-7xl mx-auto">
          <div className="flex justify-between items-center">
            <div className="space-y-1">
              <h1 className="text-3xl font-bold text-white">
                {account?.name || 'Loading...'}
              </h1>
              <p className="text-white/80">
                {account?.parentAccount ? 'Sub-account' : 'Main Account'}
              </p>
            </div>
            <div className="flex space-x-2">
              <Button 
                variant="outline" 
                size="sm"
                onClick={() => setShowAddUserDialog(true)}
                className={cn(
                  `bg-[${colors.brand.primary}]`,
                  'text-white',
                  `hover:bg-[${colors.background.secondary}]`,
                  `hover:text-[${colors.brand.primary}]`,
                  `border-[${colors.brand.primary}]`
                )}
              >
                <UserPlus className="w-4 h-4 mr-2" />
                Add Users
              </Button>
              <Button 
                variant="outline" 
                size="sm"
                onClick={() => setShowCreateAccountDialog(true)}
                className={cn(
                  `bg-[${colors.brand.primary}]`,
                  'text-white',
                  `hover:bg-[${colors.background.secondary}]`,
                  `hover:text-[${colors.brand.primary}]`,
                  `border-[${colors.brand.primary}]`
                )}
              >
                <Plus className="w-4 h-4 mr-2" />
                Create Sub-Account
              </Button>
            </div>
          </div>
  
          {/* Quick Stats */}
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mt-6">
  <Card 
    className="bg-white/95 backdrop-blur border-none shadow-lg hover:shadow-xl transition-all cursor-pointer"
    onClick={() => navigate(`/accounts/${accountId}/board/all`)}
  >
    <CardContent className="pt-6">
      <div className="flex items-center space-x-2">
        <div className="p-2 rounded-full bg-[#1f513f]/10">
          <Layout className="w-5 h-5 text-[#1f513f]" />
        </div>
        <div>
          <div className="text-2xl font-bold text-[#20201d]">
            {boards?.length || 0}
          </div>
          <div className="text-sm text-[rgba(66,59,47,0.75)] flex items-center">
            Total Boards
            <TrendingUp className="w-4 h-4 ml-1" />
          </div>
        </div>
      </div>
    </CardContent>
  </Card>

  <Card 
    className="bg-white/95 backdrop-blur border-none shadow-lg hover:shadow-xl transition-all cursor-pointer"
    onClick={() => navigate(`/accounts/${accountId}/workload/default`)}
  >
    <CardContent className="pt-6">
      <div className="flex items-center space-x-2">
        <div className="p-2 rounded-full bg-[#1f513f]/10">
          <Users className="w-5 h-5 text-[#1f513f]" />
        </div>
        <div>
          <div className="text-2xl font-bold text-[#20201d]">
            {boardsByType?.['Workload']?.length || 0}
          </div>
          <div className="text-sm text-[rgba(66,59,47,0.75)] flex items-center">
            Active Workloads
            <TrendingUp className="w-4 h-4 ml-1" />
          </div>
        </div>
      </div>
    </CardContent>
  </Card>

  <Card 
    className="bg-white/95 backdrop-blur border-none shadow-lg hover:shadow-xl transition-all cursor-pointer"
    onClick={() => navigate(`/accounts/${accountId}/board/business`)}
  >
    <CardContent className="pt-6">
      <div className="flex items-center space-x-2">
        <div className="p-2 rounded-full bg-[#1f513f]/10">
          <Briefcase className="w-5 h-5 text-[#1f513f]" />
        </div>
        <div>
          <div className="text-2xl font-bold text-[#20201d]">
            {boardsByType?.['Business']?.length || 0}
          </div>
          <div className="text-sm text-[rgba(66,59,47,0.75)] flex items-center">
            Business Boards
            <TrendingUp className="w-4 h-4 ml-1" />
          </div>
        </div>
      </div>
    </CardContent>
  </Card>
</div>
        </div>
      </div>
  
      <div className="px-6">
        {/* Template Selection Dialog */}
        <TemplateSelection
          open={showTemplateDialog}
          onOpenChange={setShowTemplateDialog}
          boardType={selectedBoardType}
          accountId={accountId}
          onBoardCreated={handleBoardCreated}
        />

        {/* Add User Dialog */}
        <AddUserDialog
          open={showAddUserDialog}
          onOpenChange={setShowAddUserDialog}
          accountId={accountId}
        />
  
 {/* Business Value Overview section */}
 {!metricsLoading && workloadMetrics?.length > 0 && (
  <div className="space-y-4 mb-12">
    <Card 
      className="border-none shadow-lg hover:shadow-xl transition-all"
    >
      <CardContent className="p-6">
        <MetricsDashboard workloadMetrics={workloadMetrics} />
      </CardContent>
    </Card>
  </div>
)}

{/* Board Sections */}
<div className="space-y-6">
  {boardTypes.map(({ type, icon: Icon, description, path }) => {
            const boardsOfType = boardsByType[type] || [];
            
            return (
              <div key={type} className="space-y-4">
                <div className="flex items-center justify-between">
                  <div>
                    <h2 className={`text-xl font-semibold flex items-center text-[${colors.text.emphasis}]`}>
                      <Icon className="w-5 h-5 mr-2" />
                      {type} Boards
                    </h2>
                    <p className={`text-sm text-[${colors.text.secondary}]`}>{description}</p>
                  </div>
                  <Button 
                    variant="outline" 
                    size="sm"
                    onClick={() => handleCreateBoard(type)}
                    className={cn(
                      `bg-[${colors.brand.primary}]`,
                      'text-white',
                      `hover:bg-[${colors.background.secondary}]`,
                      `hover:text-[${colors.brand.primary}]`,
                      `border-[${colors.brand.primary}]`
                    )}
                  >
                    <Plus className="w-4 h-4 mr-2" />
                     {type} Board
                  </Button>
                </div>
                
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                {boardsOfType.map(board => (
  <BoardCard
    key={board._id}
    board={board}
    onClick={() => handleBoardClick(board)}
    onDelete={async (boardId) => {
      try {
        await boardApi.deleteBoard(boardId);
        queryClient.invalidateQueries(['boards', accountId]);
        toast({
          title: "Board deleted",
          description: "The board has been successfully deleted."
        });
      } catch (error) {
        console.error('Error deleting board:', error);
        toast({
          title: "Error",
          description: "Failed to delete board. Please try again.",
          variant: "destructive"
        });
      }
    }}
  />
))}
                  
                  {boardsOfType.length === 0 && (
                    <EmptyBoardCard
                      type={type}
                      Icon={Icon}
                      onCreateBoard={() => handleCreateBoard(type)}
                    />
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};


// Main AccountPage Component with Routes
const AccountPage = () => {
  const { accountId } = useParams();
  const [workloadMetrics, setWorkloadMetrics] = useState([]);

  // Debug initial mount
  useEffect(() => {
    console.log('AccountPage mounted with accountId:', accountId);
  }, [accountId]);

  // Move the query before any conditional returns
  const { data: metricsData, isLoading: metricsLoading } = useQuery(
    ['workload-metrics', accountId],
    async () => {
      console.log('Starting metrics fetch for account:', accountId);
      try {
        const metrics = await metricsApi.getAccountMetrics(accountId);
        console.log('Raw metrics response:', metrics);
        return metrics;
      } catch (error) {
        console.error('Detailed metrics fetch error:', error);
        throw error;
      }
    },
    {
      enabled: Boolean(accountId),
      staleTime: 5 * 60 * 1000,
      onSuccess: (data) => {
        console.log('Setting workload metrics:', data);
        setWorkloadMetrics(data || []);
      },
      onError: (error) => {
        console.error('Query error handler:', error);
      }
    }
  );

  // Debug metrics state changes
  useEffect(() => {
    console.log('Current metrics state:', {
      accountId,
      metricsData,
      workloadMetrics,
      metricsLoading
    });
  }, [accountId, metricsData, workloadMetrics, metricsLoading]);

  // Move the conditional render after all hooks
  if (!accountId) {
    console.log('No accountId available yet');
    return (
      <Card className="border-none shadow-lg">
        <CardContent className="p-6">
          <div className="text-center py-4">
            <h3 className="text-lg font-medium text-gray-900 mb-2">
              Loading...
            </h3>
          </div>
        </CardContent>
      </Card>
    );
  }

  return (
   <Routes>
          <Route 
            index 
            element={
              <AccountDashboard 
                workloadMetrics={workloadMetrics} 
                metricsLoading={metricsLoading} 
                accountId={accountId}
              />
            } 
          />
          <Route 
  path="metrics" 
  element={
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <div className="bg-white rounded-lg shadow">
        <div className="px-6 py-4 border-b border-gray-200">
          <h2 className="text-xl font-semibold text-gray-900">Business Value Analysis</h2>
        </div>
        <div className="p-6">
          {workloadMetrics?.length > 0 ? (
            <MetricsDrillDown 
              initialMetrics={workloadMetrics}
              defaultAccountId={accountId}
            />
          ) : (
            <div className="text-center py-4">
              <Shield className="w-12 h-12 mx-auto text-gray-400 mb-4" />
              <h3 className="text-lg font-medium text-gray-900 mb-2">
                No Metrics Available
              </h3>
              <p className="text-gray-500">
                There are currently no metrics to display.
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  } 
/>
          <Route path="board/:boardType/:boardId" element={<BoardPage />} />
          <Route path="workload/:workloadId" element={<BoardPage />} />
          <Route path="settings" element={<div>Settings Page</div>} />
          <Route path="help" element={<div>Help Page</div>} />
        </Routes>
  );
};

export default AccountPage;