import React from 'react';
import { DynamicField } from './fields';  
import MetricsSection from './MetricsSection';

const BusinessCaseDetails = ({ businessCase, onFieldChange }) => {
  const boardId = businessCase?.boardId;
  const businessCaseId = businessCase?._id;

  return (
    <div className="space-y-8">
      {/* Use Case Overview */}
      <div className="bg-white rounded-lg border border-gray-200 overflow-hidden">
        <div className="px-6 py-4 bg-[#35644f] border-b border-gray-200">
          <h3 className="text-sm font-medium text-white">Use Case Overview</h3>
        </div>
        <div className="p-6">
          <DynamicField
            field={{
              key: 'useCaseDescription',
              label: 'Description',
              type: 'textarea'
            }}
            value={businessCase?.useCaseDescription}
            onChange={onFieldChange}
          />
        </div>
      </div>

      {/* Business Details */}
      <div className="bg-white rounded-lg border border-gray-200 overflow-hidden">
        <div className="px-6 py-4 bg-[#35644f] border-b border-gray-200">
          <h3 className="text-sm font-medium text-white">Business Details</h3>
        </div>
        <div className="p-6">
          <div className="grid grid-cols-2 gap-6">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Business Unit
              </label>
              <DynamicField
                field={{
                  key: 'businessUnit',
                  label: 'Business Unit',
                  type: 'text'
                }}
                value={businessCase?.businessUnit}
                onChange={onFieldChange}
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Project Owner
              </label>
              <DynamicField
                field={{
                  key: 'projectOwner',
                  label: 'Project Owner',
                  type: 'text'
                }}
                value={businessCase?.projectOwner}
                onChange={onFieldChange}
              />
            </div>
          </div>
        </div>
      </div>

      {/* Metrics Section */}
      <MetricsSection 
        boardId={boardId}
        businessCaseId={businessCaseId}
        businessCase={businessCase} 
        onFieldChange={onFieldChange}
        initialMetrics={businessCase?.metrics || []}
      />
    </div>
  );
};

export default BusinessCaseDetails;